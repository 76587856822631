import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useNumberPickerCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);

    const execute = useCallback(async () => {
        try {

            const tools = await ExtendedResourceRepository.getToolbarTools({ bookId }) || [];
            const numberSelector = tools.filter(item=>item.type && item.type === 'numberSelector');
            if(numberSelector && numberSelector.length > 0){
                const tool = numberSelector[0];
                return ExtendedResourceRepository.postTools({ bookId, filename: tool.filename })
            } else {
                window.open('https://select.oneclass.com.tw','blank')
            }

        } catch (e) {
            console.error('NumberPickerCommand execute error', e);
        }
    }, [bookId]);

    return { execute };
};
