import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
//點擊連結檔案要出現subMenu,Edit
export const useLinkCommand = () => {
    const [{ submenuInfo }, readerDispatch] = useStore(StoreTypes.reader);
    const execute = useCallback(async ({ canvasSVGObject }) => {

        const target = document.getElementById(canvasSVGObject.id);
        if (!target) return;
        var w = window.innerWidth / 2;
        var h = window.innerHeight / 2;
        let position = 'left';
        var { x, y } = target.getBoundingClientRect();
        if (x < w) {
            if (y < h) {
                position = 'right'
            } else {
                position = 'topRight'
            }
        } else {
            if (y < h) {
                position = 'left'
            } else {
                position = 'topLeft'
            }
        }
        if (!target) return;//選取跟部分刪除抓不到target 不顯示次選單
        try {
            if (submenuInfo) {
                readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: null })
            } else {
                readerDispatch({
                    type: types.SET_SUBMENU_INFO, submenuInfo: {
                        position,
                        target,
                        showEdit: true,
                        canvasSVGObject
                    }
                })
            }
        } catch (e) {
            console.error('InternalWindowCommand execute error', e);
        }
    }, [readerDispatch, submenuInfo]);
    return { execute };
};
