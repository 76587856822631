import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import BookView from 'components/BookView';
import FabricCanvas from 'components/Canvas/FabricCanvas';
import { PinchPanContainer } from 'components/PinchPanContainer';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import * as types from 'constants/actionTypes';
import ReactDOM from 'react-dom';

const BookContainer = ({ book }) => {

  const [
    {
      
      fullWidthInfo: { scale: fullWidthScale },
      objectPointerEventStatus
    }
  ] = useStore(StoreTypes.reader);
  const [
    {
      style: { width, height }
    }
  ] = useStore(StoreTypes.books);

  return (
    <>
      {book && (
        <div className={styles.bookContainer}>
          <PinchPanContainer>
            <div
              id="BookContainer"
              className={styles.bookContainer}
              style={{ transform: `scaleX(${fullWidthScale})` }}
            >

              <BookView book={book} />
              <FabricCanvas
                id="FabricCanvas"
                width={width}
                height={height}
                isDrawingMode={false}
                className={classnames(styles.canvasWrapper, { [styles.covered]: !objectPointerEventStatus})}
              />
            </div>
          </PinchPanContainer>
          
        </div>
      )}
    </>
  );
};

export default withRouter(BookContainer);
