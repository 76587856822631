import { PlatformType } from 'constants/platformType';
import CloudBookContentRepository from './BookContentRepositories/CloudBookContentRepository';
import FileBookContentRepository from './BookContentRepositories/FileBookContentRepository';
import CloudExtendedResourceRepository from './ExtendedResourceRepositories/CloudExtendedResourceRepository';
import FileExtendedResourceRepository from './ExtendedResourceRepositories/FileExtendedResourceRepository';
import CloudPreparationRepository from './PreparationRepositories/CloudPreparationRepository';
import FilePreparationRepository from './PreparationRepositories/FilePreparationRepository';
import CloudReaderToolBarRepository from './ReaderToolBarRepositories/CloudReaderToolBarRepository';
import FileReaderToolBarRepository from './ReaderToolBarRepositories/FileReaderToolBarRepository';
import FileClassNameRepository from './ClassNameRepositories/FileClassNameRepository';
import CloudClassNameRepository from './ClassNameRepositories/CloudClassNameRepository';
import CloudBookshelfRepository from './BookshelfRepowitories/CloudBookshelfRepository';
import FileBookshelfRepository from './BookshelfRepowitories/FileBookshelfRepository';
import MobileReaderToolBarRepository from './ReaderToolBarRepositories/MobileReaderToolBarRepository';

export const createRepository = platform => {
  switch (platform) {
      case PlatformType.Electron:
          return {
              classNameRepository: FileClassNameRepository,
              BookContentRepository: FileBookContentRepository,
              ExtendedResourceRepository: FileExtendedResourceRepository,
              PreparationRepository: FilePreparationRepository,
              ReaderToolBarRepository: FileReaderToolBarRepository,
              BookshelfRepository: FileBookshelfRepository,
          };

      case PlatformType.Ios:
      case PlatformType.Android:
          return {
              classNameRepository: FileClassNameRepository,
              BookContentRepository: FileBookContentRepository,
              ExtendedResourceRepository: FileExtendedResourceRepository,
              PreparationRepository: FilePreparationRepository,
              ReaderToolBarRepository: MobileReaderToolBarRepository,
              BookshelfRepository: FileBookshelfRepository,
          };

      case PlatformType.Web:
      default:
          return {
              classNameRepository: CloudClassNameRepository,
              BookContentRepository: CloudBookContentRepository,
              ExtendedResourceRepository: CloudExtendedResourceRepository,
              PreparationRepository: CloudPreparationRepository,
              ReaderToolBarRepository: CloudReaderToolBarRepository,
              BookshelfRepository: CloudBookshelfRepository,
          };
  }
}