import React, { useEffect } from 'react';
import useSetState from 'customHooks/setState';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import './content.scss';
import classnames from 'classnames';
import AudioControlsButton from 'components/common/AudioControlsButton';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;


const AboutTheAuthor = ({ data, style }) => {
  const { contents, defaultActive } = data;
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ fontSize }] = useStore(StoreTypes.extendedContent);
  const [{ tabIndex, html }, setState] = useSetState({
    tabIndex: defaultActive,
    html: ''
  });

  useEffect(() => {
    const getHtml = async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: contents[tabIndex].word ? contents[tabIndex].word.split('.json')[0] : ""
      })
      setState({ html: htmlJson.html })
    }
    getHtml();
  }, [bookId, contents, setState, tabIndex]);

  return (
    <div className={classnames(styles.aboutTheAuthor, fontSize)}>
      {
        contents.length > 1 && <div className={styles.tab}>
          {
            contents.map((list, index) => <div className={classnames(styles.list, {
              [styles.active]: tabIndex === index
            })} key={index} onClick={() => setState({ tabIndex: index })}>{list.tab}</div>)
          }
        </div>
      }
      <div className={styles.sound}>
        {
          contents[tabIndex].sound && <div className={styles.radio}><AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
            bookId,
            pathName: contents[tabIndex].sound
          })} /></div>
        }
      </div>
      {
        contents[tabIndex].image && <div className={styles.image}>
          <img
            src={`${ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].image
            })}`}
            alt=""
          />
        </div>
      }
      <div className={styles.word}>
        {
          contents[tabIndex].word && <div className={styles.word} dangerouslySetInnerHTML={{ __html: html }}></div>
        }
      </div>
    </div>
  )
}

export default AboutTheAuthor;