export const UserSettingsAPI = {
    getUserSettings: (token) => {
        return fetch(`${process.env.REACT_APP_API_DOMAIN}/users/settings`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        })
            .then(response => response.json())
            .catch(err => console.error(err));
    },
    updateUserSettings: (token, userSettings) => {
        return fetch(`${process.env.REACT_APP_API_DOMAIN}/users/settings`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userSettings })
        })
            .then(response => response.json())
            .catch(err => console.error(err));
    },
    getUserFavorites: (token) => {
        return fetch(`${process.env.REACT_APP_API_DOMAIN}/users/favorites`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        })
            .then(response => response.json())
            .catch(err => console.error(err));
    },
    updateUserFavorites: (token, favorites) => {
        return fetch(`${process.env.REACT_APP_API_DOMAIN}/users/favorites`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ favorites })
        })
            .then(response => response.json())
            .catch(err => console.error(err));
    },
    updateStampControls: (token, { bookId, stampControls }) => {
        return fetch(`${process.env.REACT_APP_API_DOMAIN}/users/stampControls`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                bookId,
                stampControls
             })
        })
            .then(response => response.json())
            .catch(err => console.error(err));
    },
}

