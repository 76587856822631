import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { useAudio } from 'customHooks/mediaElement';
import Icon from 'components/Icon';
import VolumeBar from 'components/common/Player/VolumeBar';
import TimeInfo from 'components/common/Player/TimeInfo';
import ProcessBar from 'components/common/Player/ProcessBar';
import ControlButton from 'components/common/Player/ControlButton';
import SettingPanel from 'components/common/Player/SettingPanel';
import { useBoolean } from 'customHooks/boolean';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import useSetState from 'customHooks/setState';
import { useStore, StoreTypes } from 'context';
import { useUpdateUserSettings } from 'customHooks/userSetting';
import classnames from 'classnames';
import styles from './index.module.scss';

const delay = interval => {
  return new Promise(resolve => {
    setTimeout(resolve, interval);
  });
};

const SOURCE_TYPE = {
  FULL: "FULL",
  PARTIAL: "PARTIAL"
};

const MusicPlayer = ({
  className,
  src,
  partialSrc = [],
  playlist = [],
  seekList = [],
  subtitles,
  autoPlay = true,
  isShowAudioControlBar,
  onLoad = () => { },
  onClose = () => { },
  onStop = () => { },
  setting = false,
  closeButton = true
}) => {
  const [
    isShowSetting,
    { toggle: toggleSettingShow, setFalse: setSettingClose }
  ] = useBoolean(false);

  const memoPartialSrc = useMemo(() => {
    return partialSrc;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialSrc.length]);

  const [{ audioSetting }] = useStore(StoreTypes.settings);
  const { speed, timeGap, times, usingDefault } = audioSetting;
  const updateUserSettings = useUpdateUserSettings();

  const [{
    sourceType,
    audioSrc,
    currentAudioSrcIndex,
    timesCount,
    speedState,
    timeGapState,
    timesState,
    usingDefaultState,
    isAutoPlay,
    isPartialPaused,
  }, setState] = useSetState({
    sourceType: usingDefault ? SOURCE_TYPE.FULL : SOURCE_TYPE.PARTIAL,
    audioSrc: '',
    currentAudioSrcIndex: 0,
    timesCount: 1,
    speedState: speed,
    timeGapState: timeGap,
    timesState: times,
    usingDefaultState: usingDefault,
    isAutoPlay: autoPlay,
    isPartialPaused: true
  });

  const {
    element: audio,
    state,
    controls,
    elementRef: ref,
    activeCue,
    cues,
    currentTime
  } = useAudio(
    <audio
      id="audio"
      src={audioSrc}
      playsInline
      autoPlay={isAutoPlay}
      crossOrigin="anonymous"
    >
      {subtitles.map(subtitle => (
        <>
          <track
            key={subtitle}
            default
            kind="subtitles"
            label={subtitle.language}
            srcLang={subtitle.language}
            src={subtitle.src}
          />
        </>
      ))}
    </audio>
  );

  useEffect(() => {
    state.isLoaded && controls.playbackRate(speed)
  }, [controls, speed, state.isLoaded, audioSrc])

  useEffect(() => {
    setState({ isAutoPlay: !isShowSetting ? isAutoPlay : false })
  }, [autoPlay, isAutoPlay, isShowSetting, setState])

  useEffect(() => {
    memoPartialSrc && memoPartialSrc.length < 1 && setState({ usingDefaultState: true })
  }, [memoPartialSrc, setState])

  useEffect(() => {
    const stateObj = usingDefaultState ?
      {
        sourceType: SOURCE_TYPE.FULL,
        audioSrc: src,
        timeGapState: 1,
        timesState: 1,
      } : {
        sourceType: SOURCE_TYPE.PARTIAL,
        audioSrc: memoPartialSrc[currentAudioSrcIndex],
        timeGapState: timeGap,
        timesState: times
      };
    setState(stateObj)
  }, [controls, currentAudioSrcIndex, memoPartialSrc, setState, src, timeGap, times, usingDefaultState])

  useEffect(() => {
    onLoad({
      audio,
      state,
      controls,
      ref,
      activeCue,
      cues,
      currentTime
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCue, audio, controls, cues, ref, state, currentTime]);

  const goNextAudioSrcIndex = useCallback(() => {
    if (!memoPartialSrc) return;
    const max = memoPartialSrc.length - 1;
    const newIndex = Math.min(currentAudioSrcIndex + 1, max);
    setState({ currentAudioSrcIndex: newIndex })
  }, [memoPartialSrc, currentAudioSrcIndex, setState]);

  useEffect(() => {
    setState({ timesCount: timesState, currentAudioSrcIndex: usingDefaultState ? 0 : currentAudioSrcIndex });
  }, [setState, audioSrc, timesState, usingDefaultState, currentAudioSrcIndex, controls])

  const endedHandler = useCallback(async () => {
    if (!setting && !memoPartialSrc) return;
    const restTimes = timesCount - 1;
    // 本物播放次數等於0 時 切到下一版
    if (restTimes === 0) {
      // 最後一筆結束後 關閉音檔
      if (currentAudioSrcIndex === memoPartialSrc.length - 1 || memoPartialSrc.length === 0 || sourceType === SOURCE_TYPE.FULL) {
        onClose();
        return;
      }
      await delay(timeGap);
      goNextAudioSrcIndex();
      return;
    };

    setState({ timesCount: restTimes });
    await delay(timeGap);
    controls.play();
  }, [setting, timesCount, setState, timeGap, controls, currentAudioSrcIndex, memoPartialSrc, sourceType, goNextAudioSrcIndex, onClose]);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const element = ref.current;
    element.addEventListener('ended', endedHandler);
    return () => {
      element.removeEventListener('ended', endedHandler);
    };
  }, [endedHandler, ref]);

  useEffect(() => {
    if (isShowSetting) {
      controls.pause();
      setState({ isPartialPaused: true })
    }
  }, [controls, isShowSetting, setState])

  const userSettingsHandler = (property, value) => {
    const propertyName = `${property}State`;
    setState({ [propertyName]: value });
    updateUserSettings({ audioSetting: { ...audioSetting, [property]: value } });
  }

  const onTimesChangeHandler = times => {
    userSettingsHandler('times', times);
  };

  const onSpeedChangeHandler = speed => {
    controls.playbackRate(speed);
    userSettingsHandler('speed', speed);
  };

  const onTimeGapChangeHandler = timeGap => {
    userSettingsHandler('timeGap', timeGap);
  }

  const onUsingDefaultChangeHandler = usingDefault => {
    userSettingsHandler('usingDefault', usingDefault);
  }


  const stopHandler = () => {
    setState({ currentAudioSrcIndex: 0, timesState: times, isAutoPlay: false })
    controls.pause();
    controls.seek(0);
    onStop();
  }

  const playHandler = useCallback(() => {
    setState({ isAutoPlay: true, isPartialPaused: false })
    controls.play();
  }, [controls, setState])

  const pauseHandler = useCallback(() => {
    setState({ isPartialPaused: true })
    controls.pause()
  }, [controls, setState])

  const playButtonHandler = useCallback(type => {
    if (isShowSetting) {
      setSettingClose();
    };
    // state.isPlaying ? pauseHandler() : playHandler();
    type === 'play' ? playHandler() : pauseHandler();
  }, [isShowSetting, pauseHandler, playHandler, setSettingClose])

  const settingPanelRef = useRef();

  const settingCloseHandler = useCallback(() => {
    isShowSetting && setSettingClose()
  }, [isShowSetting, setSettingClose])

  useOutsideAlerter(settingPanelRef, settingCloseHandler);

  const toggleSettingHandler = useCallback(() => {
    toggleSettingShow();
  }, [toggleSettingShow])


  useEffect(() => {
    if (sourceType === SOURCE_TYPE.PARTIAL && state.isPlaying) {
      setState({ isPartialPaused: false })
    }
  }, [setState, sourceType, state.isPlaying])

  const audioElement = React.useMemo(() => {
    return audio
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSrc]);


  return (
    <div
      className={classnames(styles.musicPlayer, className, {
        [styles.settingMode]: isShowSetting,
        [styles.show]: isShowAudioControlBar
      })}>
      <div className={styles.music}>
        {audioElement}
        <div className={styles.controlBar}>

          <div className={styles.top}>
            {
              sourceType === SOURCE_TYPE.PARTIAL ? (
                <ControlButton
                  className={styles.controlsButton}
                  onClick={() => playButtonHandler(isPartialPaused ? 'play' : 'pause')}
                >
                  {
                    isPartialPaused ? (
                      <Icon type="text" name="play" />
                    ) : (
                        <Icon type="text" name="pause" />
                      )
                  }
                </ControlButton>
              ) : (
                  <ControlButton
                    className={styles.controlsButton}
                    onClick={() => playButtonHandler(state.isPlaying ? 'pause' : 'play')}
                  >
                    {state.isPlaying ? (
                      <Icon type="text" name="pause" />
                    ) : (
                        <Icon type="text" name="play" />
                      )}
                  </ControlButton>
                )
            }


            <ControlButton
              className={styles.controlsButton}
              onClick={() => stopHandler()}
            >
              <Icon type="text" name="stop" />
            </ControlButton>

            <div className={styles.processBarWrapper}>
              <ProcessBar
                className={styles.processBar}
                currentTime={state.time}
                endTime={state.duration}
                controls={controls}
              />
            </div>

            <VolumeBar
              className={styles.volumeBar}
              state={state}
              controls={controls}
              onChange={val => controls.volume(val)}
              hoverable={false}
              width={60}
            />
            {
              setting && <ControlButton
                className={classnames(styles.controlsButton, {
                  [styles.isShowSetting]: isShowSetting
                })}
              >
                <div
                  className={classnames(styles.settingButton, {
                    [styles.active]: isShowSetting
                  })}
                  onClick={toggleSettingHandler}>
                  <Icon type="text" name="cog" />
                </div>
              </ControlButton>
            }
            {
              closeButton && <ControlButton
                className={classnames(styles.controlsButton)}
                onClick={onClose}
              >
                <div className={styles.close}>
                  <Icon type="text" name="times" />
                </div>
              </ControlButton>
            }
          </div>
          <div className={styles.bottom}>
            <TimeInfo
              className={styles.timeInfo}
              currentTime={state.time}
              endTime={state.duration}
            />
          </div>
        </div>
      </div>
      {
        setting && (
          <SettingPanel
            ref={settingPanelRef}
            hideDefaultCheckBox={memoPartialSrc.length < 1}
            value={{
              speed: speedState,
              timeGap: timeGapState,
              times: timesState,
              usingDefault: usingDefaultState
            }}
            active={isShowSetting}
            advancedSettings={memoPartialSrc.length > 0}
            className={styles.settingPanel}
            onTimesChange={onTimesChangeHandler}
            onSpeedChange={onSpeedChangeHandler}
            onTimeGapChange={onTimeGapChangeHandler}
            onUsingDefaultChange={onUsingDefaultChangeHandler}
          />
        )
      }
    </div>
  );
};
export default MusicPlayer;
