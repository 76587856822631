import React, { useEffect, useRef, forwardRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import useSetState from 'customHooks/setState';
import AudioControlsButton from 'components/common/AudioControlsButton';
import Repository from 'repositories/Repository';
import { useStore, StoreTypes } from 'context';
import * as actionTypes from 'constants/actionTypes';
// import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import Bopomofo from 'components/common/Bopomofo';
import Icon from 'components/Icon';
import classnames from 'classnames';
import './content.scss'
const { ExtendedResourceRepository, classNameRepository } = Repository;

const WordLearningContent = forwardRef(({
  htmlPath,
  imgPath,
  audioPath,
  wrapperRef,
  itemRef,
  zhuyin = null,
  target,
  onAudioClick = () => { },
  changeScrollBarState
}, ref) => {
  const portalTarget = target || document.body;
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ fontSize }] = useStore(StoreTypes.extendedContent);
  let [{ tabIndex, word, extensionType, relatedWords }, wordGridDispatch] = useStore(StoreTypes.wordGrid);
  // tabIndex [ 組別 (object), 區分地『解釋』或『例句』]
  const [{
    html,
    isImgModalShow,
    isContentLoading,
  }, setState] =
    useSetState({ html: '', isImgModalShow: false, isContentLoading: false });
  // const modalImgRef = useRef();


  useEffect(() => {
    setState({ isContentLoading: true })
    setTimeout(() => setState({ isContentLoading: false }))
  }, [fontSize, setState, word, html])

  useEffect(() => {
    if (!itemRef || itemRef.current.length < 0 || !itemRef.current[0]) return;
    itemRef.current = itemRef.current.filter(item => item) // 去除 null
    const group = itemRef.current[tabIndex[0]];
    if (group) { // 判斷 undefined
      const article = group.getElementsByClassName('article')
      if (article.length > 0) {
        const paragraph = article[0].getElementsByClassName('paragraph')[1];
        // 捲軸移動
        if (tabIndex[1] === 0) { // 判斷『解釋』或『例句』
          group.scrollIntoView({ behavior: "smooth", inline: ExtendedResourceRepository.getWordGridAnchorDirection() });
        } else {
          paragraph && paragraph.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, itemRef, isContentLoading])

  useEffect(() => {
    changeScrollBarState(false);
    if (!isContentLoading) return;
    setTimeout(() => {
      const articles = wrapperRef.current.getElementsByClassName("article");
      for (let i = 0; i < articles.length; i++) {
        articles[i].style.height = wrapperRef.current.clientHeight + "px";
      }
      const wordLearningGrid = wrapperRef.current.getElementsByClassName("wordLearningGrid");
      for (let i = 0; i < wordLearningGrid.length; i++) {
        wordLearningGrid[i].classList.add('flex-width');
      }

      // 捲軸判斷
      const scrollWidth = wrapperRef.current.scrollWidth;
      const clientWidth = wrapperRef.current.clientWidth;
      const scrollBarOver = scrollWidth - clientWidth > 0;

      if (scrollBarOver) {
        changeScrollBarState(true);
      }
    }, 500)
  }, [isContentLoading, wrapperRef])

  useEffect(() => {
    const getHtml = async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: htmlPath ? htmlPath.split('.json')[0] : ""
      })
      setState({ html: htmlJson.html })
    }
    getHtml();
    if (wrapperRef) {
      wrapperRef.current && wrapperRef.current.scrollTo(wrapperRef.current.scrollWidth, 0);
      wordGridDispatch({
        type: actionTypes.SET_CHANGE_TAB_INDEX,
        payload: {
          tabIndex: 0
        }
      })
    }
  }, [bookId, htmlPath, setState, wordGridDispatch, wrapperRef]);
  const onImgClickHandler = () => {
    setState({ isImgModalShow: true })
  }
  const imgModalCloseHandler = () => {
    setState({ isImgModalShow: false })
  }
  // useOutsideAlerter(modalImgRef, imgModalCloseHandler)

  return !isContentLoading && (
    <div className={classnames(styles.wordLearningContent, fontSize,
      { [classNameRepository.getClassName('wordLearningContent')]: !zhuyin }
    )} ref={ref}>
      {
        audioPath && <AudioControlsButton
          onClick={onAudioClick}
          className={styles.audio}
          src={ExtendedResourceRepository.getDataSrc({
            bookId,
            pathName: audioPath
          })}
        />
      }
      {
        zhuyin && (
          <div className={styles.zhuyinLabel}>
            {
              Array.isArray(zhuyin) && (
                <>
                  {
                    zhuyin.map(item => (
                      <Bopomofo
                        className={styles.bopomofo}
                        zhuyin={item}
                      />
                    ))
                  }
                </>
              )
            }
          </div>
        )
      }
      {
        imgPath &&
        <div className={classnames(styles.imageBox, classNameRepository.getClassName('imageBox'))}>
          <div style={{
            backgroundImage: `url(${ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: imgPath
            })})`
          }} className={styles.image} onClick={onImgClickHandler} />
          <div className={classnames(styles.prompt, classNameRepository.getClassName('prompt'))}>點圖放大</div>
        </div>
      }
      {
        html && <div className={classNameRepository.getClassName('content')} style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: html }}></div>
      }
      {
        isImgModalShow && (
          ReactDOM.createPortal(
            <div className={styles.imageModal}>
              <div className={styles.image}>
                <img
                  src={`${ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: imgPath
                  })}`}
                  alt=""
                />
                <div className={styles.icon} onClick={imgModalCloseHandler}>
                  <Icon type="text" name="times" />
                </div>
              </div>
            </div>
            , portalTarget)
        )
      }
    </div >
  )
})
export default WordLearningContent;
export const WordLearningContentWrapper = ({
  data = []
}) => {
  const contentRef = useRef();
  const wordItemRef = useRef([]);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const onAudioClickHandler = index => audioRef => {
    const audios = contentRef.current.getElementsByTagName('audio');
    for (let i = 0; i < audios.length; i++) {
      if (i === index) continue;
      audios[i].pause();
    }
    audioRef.paused ? audioRef.play() : audioRef.pause();
  }

  // 至底訊息
  const scrollBarStateHandler = (state) => {
    setHasScrollBar(state);
  }

  return (
    <div ref={contentRef} className={styles.WordLearningContentWrapper}>
      {
        data.map((item, index) => (
          <WordLearningContent
            zhuyin={item.Label || item.label}
            itemRef={wordItemRef}
            ref={v => wordItemRef.current[index] = v}
            wrapperRef={contentRef}
            htmlPath={item.Content}
            imgPath={item.Path}
            audioPath={item.Audio}
            onAudioClick={onAudioClickHandler(index)}
            changeScrollBarState={scrollBarStateHandler}
          />
        ))
      }
      {hasScrollBar ? <div className={styles.bottomPrompt}><span>已至底</span></div> : null}
    </div>
  )
}