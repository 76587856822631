import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Provider from './context';
import { AppService } from 'components/AppService';
import Bookshelf from 'components/Bookshelf';
import { BookStore } from 'components/BookStore';
import Placeholder from 'components/Placeholder';
import ReaderActivity from 'components/ReaderActivity';
import { Beforeunload } from 'components/Beforeunload';

const HookedBrowserRouter = ({ children }) => (
  <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
    <Route>
      {(routeProps) => (
        <Provider router={routeProps}>
          {children}
        </Provider>
      )}
    </Route>
  </BrowserRouter>
);

const App = () => {
  return (
    <Beforeunload>
      <HookedBrowserRouter>
        <AppService />
        <Switch>
          <Route exact path='/bookshelf' component={Bookshelf} />
          <Route exact path='/bookstore' component={BookStore} />
          <Route exact path='/loading' component={Placeholder} />
          <Route exact path='/:bookId' component={ReaderActivity} />
          <Redirect path='*' to='/bookshelf' />
        </Switch>
      </HookedBrowserRouter>
    </Beforeunload>
  );
};

export default App;

