import { useReaderEventHandler } from 'events/eventHandlers/ReaderEventHandler';
import { useUserEventHandler } from 'events/eventHandlers/UserEventHandler';
import { useExtendedContentEventHandler } from 'events/eventHandlers/ExtendedContentEventHandler'
import { useInitializeActivity } from 'components/AppService/ActivityInitializer';
import { useGAPageView } from 'components/AppService/GAService';
import { useOnlineCheckInit } from "components/AppService/OnlineCheck";
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getBookIdFromNative } from 'util/native';



const useMobileRedirect = () => {
    const history = useHistory();
    const bookId = getBookIdFromNative();
    useEffect(()=>{
        bookId && history.push(`/${bookId}`);
    },[bookId, history])
}

export const AppService = () => {
    useGAPageView();
    useMobileRedirect();
    useReaderEventHandler();
    useUserEventHandler();
    useInitializeActivity();
    useExtendedContentEventHandler();
    useOnlineCheckInit();
    return null;
};
