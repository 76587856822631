import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { BOOK_ORIENTATION } from 'constants/books';

export const initState = {
  style: {
    width: null,
    height: null,
    offsetX: null
  },
  books: [],
  bookList: {
    lists: [],
    total: null
  },
  bookId: '',
  catalog: [],
  bookContent: {},
  interactiveObjectJSON: {},
  interactiveObjectSVG: {},
  orientation: BOOK_ORIENTATION.PORTRAIT
};

const booksReducer = (state, action) => {
  switch (action.type) {
    case types.SET_BOOKS:
      const mergedBooks = [...action.payload, ...state.books.filter(book => !action.payload.some(v => book.bookId === v.bookId))];
      return { ...state, books: mergedBooks };
    case types.GET_BOOKLIST:
      return { ...state, bookList: { ...state.bookList, lists: action.payload.lists, total: action.payload.total } };
    case types.GET_BOOKSHELF:
      return { ...state, books: [...action.payload] };
    case types.SET_BOOK_ID:
      return { ...state, bookId: action.payload };
    case types.SET_BOOK_STYLES:
      return {
        ...state,
        style: { ...action.payload }
      };
    case types.SET_BOOK_CATALOG:
      return { ...state, catalog: action.catalog };
    case types.SET_BOOK_CONTENT:
      return { ...state, bookContent: { ...state.bookContent, ...action.bookContent } };
    case types.SET_INTERACTIVE_OBJECTS:
      return { ...state, interactiveObjectJSON: { ...state.interactiveObjectJSON, ...action.interactiveObjectJSON }, interactiveObjectSVG: { ...state.interactiveObjectSVG, ...action.interactiveObjectSVG } };
    case types.SET_INTERACTIVE_OBJECTS_SVG:
      return { ...state, interactiveObjectSVG: action.interactiveObjectSVG };
    case types.SET_BOOK_ORIENTATION:
      return { ...state, orientation: action.orientation };
    case types.RESET_BOOK_CONTENT:
      return {
        ...state,
        bookId: initState.bookId,
        catalog: initState.catalog,
        bookContent: initState.bookContent,
        interactiveObjectJSON: initState.interactiveObjectJSON,
        interactiveObjectSVG: initState.interactiveObjectSVG
      };
    default:
      return state;
  }
};

const useBooksReducer = () => useEnhanceReducer(booksReducer, initState);

export default useBooksReducer;
