import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { EventBusType } from 'events/EventBus';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedPartialButton = ({ canvasState }) => {
  const [{ partialInfo }] = useStore(StoreTypes.extendedContent);
  const { canvasSVGObjectId } = canvasState;
  const {
    viewHeight,
    scrollHeight,
    scrollTop,
    space
  } = partialInfo;

  const onScrollHandler = (type) => {
    let scrollHh = 0
    switch (type) {
      case 'prev':
        scrollHh = (scrollTop <= 0) ? 0 : scrollTop - space;
        break;
      case 'next':
        scrollHh = (scrollTop + viewHeight >= scrollHeight) ? scrollHeight - viewHeight : scrollTop + space;
        break;
      default:
        break;
    }

    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent,
      payload: { canvasSVGObjectId }
    });

    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetExtendedContentPartialInfo,
      payload: {
        partialInfo: {
          ...partialInfo,
          scrollTop: scrollHh,
        }
      }
    });
  }

  return (
    <div className={styles.partialButton}>
      <div className={classnames(styles.button, { [styles.disabled]: scrollTop <= 0 })}
        onClick={() => onScrollHandler('prev')}>
        上一段
      </div>
      <div className={classnames(styles.button, { [styles.disabled]: (scrollTop + viewHeight >= scrollHeight) })}
        onClick={() => onScrollHandler('next')}>
        下一段
      </div>
    </div>
  );
};
