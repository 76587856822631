import { useCallback } from 'react';
import * as types from 'constants/actionTypes';
import { useStore, StoreTypes } from 'context';
import { useEvent } from 'events/EventBus';
import { ExtendedContentEvent } from 'events/EventTypes';

export const useExtendedContentEventHandler = () => {

  const [,extendedContentDispatch] = useStore(StoreTypes.extendedContent);

  const setWordLearningGridPlaybackRateHandler = useCallback(({playbackRate}) => {
      extendedContentDispatch({
        type: types.SET_EXTENDED_CONTENT_WORD_LEARNING_GRID_PLAYBACK_RATE, payload: {
          playbackRate
        }
      })
    },
    [extendedContentDispatch]
  );

  useEvent(
    { event: ExtendedContentEvent.SetWordLearningGridPlaybackRateEvent },
    setWordLearningGridPlaybackRateHandler
  );
}
