import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import lottie from 'lottie-web'
import classnames from 'classnames';
import { ExpressionTypes } from 'constants/expressionTypes';

const Expression = ({ className, expressionType, loop, onComplete }) => {
    const ref = useRef();
    const data = ExpressionTypes[expressionType] ? ExpressionTypes[expressionType] : null

    useEffect(() => {
        if (!ref.current || !data) return;
        const animate = lottie.loadAnimation({
            container: ref.current, // the dom element that will contain the animation
            renderer: 'svg',
            loop,
            autoplay: true,
            path:data.path, // the path to the animation jso
        });
        animate.onComplete = onComplete;
        return () => {
            animate.onComplete = null;
        }
    }, [ref, loop, onComplete,data])
    return (
        expressionType && (
            <>
                <div className={classnames(styles.animation, className)} ref={ref} />
                <audio
                    src={data.soundSrc}
                    playsInline
                    autoPlay={true}
                    crossOrigin="anonymous"
                >
                </audio>
            </>
        )
    )
}

export default Expression;