import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { ReaderZoomType, } from 'constants/ReaderTools';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import Icon from 'components/Icon';

const AreaZoomController = ({ areaZoomInteractiveObjects }) => {
    const reducers = useStore();
    const [{ readerToolHeight }, readerDispatch] = reducers[StoreTypes.reader];
    const height = useMemo(()=> window.innerHeight - readerToolHeight,[readerToolHeight]);
    const { nowStep, areaZoomInfos } = areaZoomInteractiveObjects

    const prevBtnShow = useMemo(()=> {
        if(parseInt(nowStep) === 1) return false;
        return true;
    },[nowStep]);

    const nextBtnShow = useMemo(()=> {
        if(!areaZoomInfos[nowStep]) return false
        return true;
    },[nowStep,areaZoomInfos]);

    const showHideBtn = useCallback((type)=>{
        let index = parseInt(nowStep);
        if(type === 'next') index = index + 1
        if(type === 'prev') index = index - 1   
        const areaZoomInfoObject = areaZoomInfos[index -1]
        areaZoomInfos && EventBus.emit({ 
            event: ReaderEvent.AreaZoomForPageButtonEvent, payload: { 
                rect: areaZoomInfoObject,
                areaZoomInteractiveObjects:{
                    ...areaZoomInteractiveObjects,
                    step: index - 1,
                    nowStep: index
                },
            }
            });
    },[nowStep, areaZoomInfos, areaZoomInteractiveObjects])

    const exitBtn = useCallback(() => {
        EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.OriginZoom } })
        readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInfos: null })
    }, [readerDispatch])

    return (
        <div className={styles.areaZoomController} style={{height : height + 'px' }}>
            <div className={styles.leftExit}>
                <div className={styles.btn} onClick={() => exitBtn()}><Icon name="closeMathAreaZoomButton" /></div>
            </div>
            <div className={styles.prevNextContent}>
                <div className={classnames(styles.btn, styles.prev, styles[prevBtnShow && 'show'])} onClick={() => showHideBtn('prev')}><Icon name="prevMathAreaZoomButton" /></div>
                <div className={classnames(styles.btn, styles.next, styles[nextBtnShow && 'show'])} onClick={() => showHideBtn('next')}><Icon name="nextMathAreaZoomButton" /></div>
            </div>
            <div className={classnames(styles.btn, styles.rightExit)}>
                <div className={styles.btn} onClick={() => exitBtn()}><Icon name="closeMathAreaZoomButton" /></div>
            </div>
        </div>
    );
};

export default AreaZoomController;
