import React, { Fragment } from 'react';
import useSetState from 'customHooks/setState';
import useInterval from 'customHooks/interval';
import AudioControlsButton from 'components/common/AudioControlsButton';
import Icon from 'components/Icon';
import WordRadicalContent from './WordRadicalContent';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import { ExtendedContentContainer } from 'components/ExtendedContent/ExtendedContentContainer';
import * as actionTypes from 'constants/actionTypes';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const WordRadical = ({ data = {} }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const { Path, Progress, Content, Audio, OtherSourceUrl } = data;
  const [
    { sliderIndex, wordRadicalProgressIndex },
    setState
  ] = useSetState({
    sliderIndex: 0,
    wordRadicalProgressIndex: 0
  });

  const [{ isShowRadicalProgressModal }, wordGridDispatch] = useStore(StoreTypes.wordGrid);


  const maxItemListIndex = Progress.length - 1;

  useInterval(() => {
    let index = sliderIndex + 1;
    if (index <= 0) index = maxItemListIndex;
    if (index > maxItemListIndex) index = 0;
    setState({ sliderIndex: index });
  }, 3000);

  const progressModalHandler = status => {
    wordGridDispatch({
      type: actionTypes.SET_RADICAL_PROGRESS_MODAL_SHOW,
      payload: {
        isShowRadicalProgressModal: status,
      }
    })
    
  };

  const wordRadicalProgressChangeHandler = index => {
    setState({
      wordRadicalProgressIndex: Math.max(0, Math.min(index, maxItemListIndex))
    });
  };

  return (
    <div className={styles.wordRadical}>
      <div className={styles.mainContent}>
        <div className={styles.right}>
          <div className={styles.audio}>
            {
              Audio && (
                <AudioControlsButton
                  src={ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: Audio
                  })}
                />
              )
            }
            {
              OtherSourceUrl && OtherSourceUrl.map(item => (
                <a className={styles[item.type]} href={item.url} target="_blank" rel="noopener noreferrer">
                  <div className={styles.videoIcon} style={{ backgroundImage: 'url(assets/img/video.svg)' }} />
                </a>
              )
              )
            }
          </div>
        </div>
        <div className={classnames(styles.content, {
          [styles.noprogress]: Progress.length === 0
        })}>
          <WordRadicalContent htmlPath={Content} imgPath={Path} />
        </div>
        {
          Progress.length > 0 && (
            <div className={styles.progress}>
              <div className={classnames(styles.progressSlider, {
                [styles.pointer]: Progress.length > 1
              })}>
                <div className={styles.slider}>
                  {Progress.map((item, index) => (
                    <img
                      key={item.Path}
                      src={ExtendedResourceRepository.getDataSrc({
                        bookId,
                        pathName: item.Path
                      })}
                      alt=""
                      onClick={() => progressModalHandler(Progress.length > 1)}
                      className={classnames(styles.img, {
                        [styles.active]: index === sliderIndex
                      })}
                    />
                  ))}
                </div>
                {
                  Progress.length > 1 && (
                    <span
                      className={styles.icon}
                      onClick={() => progressModalHandler(true)}
                    >
                      <Icon type="text" name="searchPlus" />
                    </span>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
      {isShowRadicalProgressModal && (
        <div className={styles.progressModal}>
          <ExtendedContentContainer
            // extendedContentType={ExtendedContentType.WordGridSubContent}
            onClose={() => progressModalHandler(false)}
          >
            <div className={styles.progressModalContent}>
              <div className={styles.itemList}>
                {Progress.map((item, index) => (
                  <Fragment key={item.Path}>
                    <div
                      className={classnames(styles.item, {
                        [styles.show]: index <= wordRadicalProgressIndex
                      })}
                    >
                      <img
                        src={ExtendedResourceRepository.getDataSrc({
                          bookId,
                          pathName: item.Path
                        })}
                        alt=""
                      />
                      <div className={styles.info}>{item.Info}</div>
                    </div>
                    {index !== maxItemListIndex && (
                      <div
                        className={classnames(styles.icon, {
                          [styles.show]: index <= wordRadicalProgressIndex
                        })}
                      >
                        <Icon type="text" name="angleRight" />
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
              <div className={styles.controls}>
                <div className={styles.center}>
                  <span
                    onClick={() =>
                      wordRadicalProgressChangeHandler(
                        wordRadicalProgressIndex - 1
                      )
                    }
                  >
                    <Icon type="text" name="angleLeft" />
                  </span>
                  <span
                    onClick={() =>
                      wordRadicalProgressChangeHandler(
                        wordRadicalProgressIndex + 1
                      )
                    }
                  >
                    <Icon type="text" name="angleRight" />
                  </span>
                </div>
              </div>
            </div>
          </ExtendedContentContainer>
        </div>
      )}
    </div>
  );
};

export default WordRadical;
