import { IReaderToolBarRepository } from './IReaderToolBarRepository';
import {
    ReaderToolType,
    DefaultLeftReaderTools,
    DefaultMiddleReaderTools,
    DefaultRightReaderTools,
    DefaultReaderTools,
    DefaultToolboxCategory,
} from 'constants/ReaderTools';

function MobileReaderToolBarRepository() { };
MobileReaderToolBarRepository.prototype = Object.create(IReaderToolBarRepository);

MobileReaderToolBarRepository.prototype.getDefaultReaderTools = () => {
    return DefaultReaderTools;
};

MobileReaderToolBarRepository.prototype.getLeftReaderTools = () => {
    return DefaultLeftReaderTools;
};

MobileReaderToolBarRepository.prototype.getMiddleReaderTools = () => {
    const blackList = [
        ReaderToolType.BackToCatalog,
        ReaderToolType.MathTools,
    ];

    const results = DefaultMiddleReaderTools.filter(item => !blackList.includes(item))
    return results;
};

MobileReaderToolBarRepository.prototype.getRightReaderTools = async (bookId) => {
    const results = [...DefaultRightReaderTools];
    return results;
};

MobileReaderToolBarRepository.prototype.getGuestReaderToolBarWhitelist = () => {
    return [
        ReaderToolType.Toolbox,
        ReaderToolType.BackToCatalog,
        ReaderToolType.Menu,
        ReaderToolType.PageMode,
        ReaderToolType.Mark,
        ReaderToolType.Drag,
        ReaderToolType.Select,
        ReaderToolType.Painter,
        ReaderToolType.Painting,
        ReaderToolType.Highlighter,
        ReaderToolType.Shape,
        ReaderToolType.Line,
        ReaderToolType.DeleteAll,
        ReaderToolType.Delete,
        ReaderToolType.ArrowLeft,
        ReaderToolType.ArrowRight,
        ReaderToolType.PageSearcher,
        ReaderToolType.FourZoomIn,
        ReaderToolType.AllScreen,
        ReaderToolType.IntervalZoomIn,
        ReaderToolType.Whiteboard,
        ReaderToolType.NumberPicker,
        ReaderToolType.Img,
        ReaderToolType.Text,
        //ReaderToolType.SVG,
        ReaderToolType.Bookmark,
        ReaderToolType.ReaderOption,
        ReaderToolType.RightSideBar,
        ReaderToolType.FullWidth,
        ReaderToolType.StickyNote,
        ReaderToolType.Link,
        ReaderToolType.MathTools,
    ];
};

MobileReaderToolBarRepository.prototype.getReaderToolboxCategory = () => {
    return [...DefaultToolboxCategory]
};

export default new MobileReaderToolBarRepository();
