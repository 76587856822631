import React, { useRef, useEffect, useCallback, useState } from "react";
import ReaderTools from 'components/ReaderTools';
import ReaderView from 'components/ReaderView';
import Modal from 'components/common/Modal';
import useModal from 'components/common/Modal/useModal';
import ActivityWork from 'components/Activity/ActivityWork';
import { useStore, StoreTypes } from 'context';
import * as actionTypes from 'constants/actionTypes';
import { AnnotationType } from 'constants/annotationTypes';
import { BOOK_ORIENTATION } from 'constants/books';
import { Roles } from 'constants/role';
import { useFetchBooks } from 'customHooks/book';
import { useCanvasEvents } from 'customHooks/canvas';
import { usePullAnnotations } from 'customHooks/reader';
import { debounce } from 'util/debounce';
import { useBookCanvasEventHandler } from 'events/eventHandlers/BookCanvasEventHandler';
import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import styles from './index.module.scss';
import classnames from 'classnames';
import { Indicator } from 'components/Indicator';
import * as types from 'constants/actionTypes';
import ActivityPanel from 'components/Activity/ActivityPanel';
import Submenu from 'components/Submenu';
import AreaZoomController from 'components/AreaZoomController';
import { useUpdateDisplayLanguage, useUpdateUserSettings } from 'customHooks/userSetting';
import { UserSettingsAPI } from 'api/UserSettingsAPI';
import { useAnnotationChecker } from 'customHooks/annotationChecker';
import MathToolsContainer from 'components/MathToolsContainer';
import SynchronousCamera from 'components/SynchronousCamera';
import { LOGIN_POPUP, LOGIN_CONFIRM_POPUP } from "constants/loginTypes";
import LoginPopup from "components/Login/Popup";
import DialogModule from 'components/Dialogs/'
import Button from "@material-ui/core/Button";
import ConfirmPopup from "components/Login/ConfirmPopup";
import CheckOnlinePopup from "components/Login/CheckOnlinePopup";
import { useOnlineCheck } from 'customHooks/onlineCheck'

import Repository from 'repositories/Repository';

const Reader = props => {
  const { bookId, paramsRole, paramsActivityId, paramsTeacherId, paramsInteractiveObjectId, roomId, studentId } = props
  const [canvasState, canvasDispatch] = useStore(StoreTypes.canvas);
  const [{ isOnLineDialog, isSynchronousCamera, mathToolsInfo, areaZoomInteractiveObjects, submenuInfo, isDoublePageMode, pageIndex, readerToolDirection, indicatorInfo: { isActive: isIndicatorActive, message: indicatorMessage } }, readerDispatch] = useStore(StoreTypes.reader);
  const [{ annotationType, activityInfo: { attendeeNumber } }, annotationDispatch] = useStore(StoreTypes.annotation);
  const [{ books, bookContent, orientation }, bookDispatch] = useStore(StoreTypes.books);
  const [{ role, token, userId, isInitialized }, userDispatch] = useStore(StoreTypes.user);

  const { isLoading, setLoading, fetchBooks, fetchBook, getBookCatalog } = useFetchBooks();
  const [{ isShowing, components }, { openModal, closeModal }] = useModal();
  const { addCanvasImage } = useCanvasEvents({ canvasState, canvasDispatch });
  const { pullInteractiveMetaObjects } = usePullAnnotations();
  const { updateDisplayLanguage } = useUpdateDisplayLanguage();
  const updateUserSettings = useUpdateUserSettings();
  const [{ loginBtn, confirmPopupState }, loginDispatch] = useStore(StoreTypes.login);
  const [{ isOnline }] = useStore(StoreTypes.global);
  const { ReaderToolBarRepository } = Repository;

  const imageFileHandlerRef = useRef(null);
  const checkIsOnline = useOnlineCheck()

  useBookCanvasEventHandler({ canvasState, canvasDispatch, imageFileHandlerRef });

  useEffect(() => {
    (async () => {
      if (token) {
        const res = await UserSettingsAPI.getUserSettings(token);
        if (res.status === 'success') {
          const { readerToolSetting } = res.data
          const middle = ReaderToolBarRepository.getMiddleReaderTools();

          const toolSetting = [
            ...middle.filter(item => !readerToolSetting.includes(item)).map(item => ({ id: item, isActive: false })),
            ...readerToolSetting.map(item => ({ id: item, isActive: true }))
          ]
          EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolsEvent,
            payload: {
              readerTools: toolSetting
            }
          });

          await updateUserSettings(res.data, false);
        }
      }
      updateDisplayLanguage();
    })();
  }, [ReaderToolBarRepository, token, updateDisplayLanguage, updateUserSettings]);

  useEffect(() => {
    if (paramsRole === Roles.STUDENT) {
      userDispatch({ type: actionTypes.UPDATE_USER_INFO, role: paramsRole });
      annotationDispatch({
        type: types.UPDATE_ANNOTATION_INFO,
        annotationType: AnnotationType.ACTIVITY
      });
      annotationDispatch({ type: types.TOGGLE_ACTIVITY_PANEL, enable: true });
    }

    return () => {
      bookDispatch({ type: types.RESET_BOOK_CONTENT });
      annotationDispatch({ type: types.RESET_ANNOTATION_INFO });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!bookId || !isInitialized) return;
    const book = books.find(book => book.bookId === bookId);
    if (!book) {
      (async () => {
        const fetchedBooks = await fetchBooks({ token, bookIds: [bookId] });

        bookDispatch({ type: actionTypes.SET_BOOK_ID, payload: bookId });
        let pages = [];
        if (isDoublePageMode) {
          pages = [pageIndex * 2, pageIndex * 2 + 1];
        } else {
          pages = [pageIndex];
        }
        pages = pages.filter(page => !bookContent[page]);
        if (pages.length > 0) {
          fetchBook({ givenBooks: fetchedBooks, bookId, pages });
        }
      })();
    } else {
      setLoading(false);
    }
    getBookCatalog({ bookId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, isInitialized]);

  useEffect(() => {
    const book = books.find(book => book.bookId === bookId);
    if (book && role === Roles.EDITOR) {
      (async () => {
        readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: true } });
        readerDispatch({ type: types.SET_FULL_WIDTH_INFO, fullWidthInfo: { mode: false } });
        const result = await pullInteractiveMetaObjects({ id: paramsInteractiveObjectId, book });
        if (result) {
          annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId: paramsInteractiveObjectId, annotationType: AnnotationType.INTERACTIVE_OBJECT });
          EventBus.emit({ event: ReaderEvent.RefreshCanvasEvent, payload: { result } });
        }
        readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: false } });
      })();
    }
  }, [annotationDispatch, bookId, books, paramsInteractiveObjectId, pullInteractiveMetaObjects, readerDispatch, role]);

  useEffect(() => {
    const book = books.find(book => book.bookId === bookId);
    if (book) {
      if (book.width > book.height) {
        bookDispatch({
          type: types.SET_BOOK_ORIENTATION,
          orientation: BOOK_ORIENTATION.LANDSCAPE
        });
        readerDispatch({
          type: types.SWITCH_BOOK_PAGES_SHOW,
          isDoublePageMode: false
        });
      }
    }
  }, [bookDispatch, bookId, books, readerDispatch]);

  const handleImageFileChange = e => {
    const reader = new FileReader();
    reader.onload = function (event) {
      if (event.total / 1024 > 500) {
        alert("提醒您！圖檔超過500kb無法存入備課紀錄，建議您將圖檔壓縮後再插入，謝謝。")
        return;
      }
      const image = new Image();
      image.src = event.target.result;
      image.onload = function () {
        addCanvasImage({ image });
      };
    };
    reader.readAsDataURL(e.target.files[0]);
    imageFileHandlerRef.current.value = '';	// reset after read file
  };

  useEffect(() => {
    const resizeHandler = debounce(e => {
      const windowHeight = e.target.innerHeight;
      const windowWidth = e.target.innerWidth;
      if (windowHeight > windowWidth || orientation === BOOK_ORIENTATION.LANDSCAPE) {
        EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: false } });
      } else if (windowHeight < windowWidth) {
        EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: true } });
      }
    }, 100);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, [isDoublePageMode, orientation, pageIndex]);

  const readerClass = (role) => {
    switch (role) {
      case Roles.ONECLASS_TEACHER:
        return styles.teacherMode;
      case Roles.ONECLASS_STUDENT:
        return styles.studentMode;
      default:
        return styles.otherMode;
    }
  }

  const showLoginPopup = useCallback(async (e, str) => {
    if (str === "logOut") {
      loginDispatch({
        type: LOGIN_CONFIRM_POPUP,
        confirmPopupState: true,
      });
    } else {
      const isOnline = await checkIsOnline()
      if (isOnline) {
        loginDispatch({ type: LOGIN_POPUP, popupState: true });
      } else {
        readerDispatch({
          type: types.SET_ONLINE_DIALOG,
          isOnLineDialog: true,
        });
      }
    }
  }, [checkIsOnline, loginDispatch, readerDispatch]);

  useEffect(() => {
    window.electron && window.electron.ipcRenderer.send("set-token", token);
    window.electron && window.electron.ipcRenderer.on("showLoginPopup", showLoginPopup);
    return () => {
      window.electron && window.electron.ipcRenderer.removeListener("showLoginPopup", showLoginPopup);
    }
  }, [token, showLoginPopup]);

  useEffect(() => {
    if (window.electron && isSynchronousCamera) {
      if (isOnline) {
        if (!token) {
          loginDispatch({ type: LOGIN_POPUP, popupState: true });
        }
      } else {
        readerDispatch({ type: types.SET_ONLINE_DIALOG, isOnLineDialog: true });
      }
    }
  }, [isSynchronousCamera, token, isOnline, loginDispatch, readerDispatch]);

  const openSynchronousCamera = (isSynchronousCamera, isOnline, userId) => {
    if (isOnline) {
      if (userId) {
        return <SynchronousCamera isSynchronousCamera={isSynchronousCamera} />;
      }
    }
  };

  return !isLoading && (
    <div className={classnames(styles.reader, styles[readerToolDirection],
      readerClass(role))}>
      {
        openSynchronousCamera(isSynchronousCamera, isOnline, userId)
      }
      {isIndicatorActive && <Indicator message={indicatorMessage} />}
      {
        mathToolsInfo && <MathToolsContainer mathToolsInfo={mathToolsInfo} />
      }
      <ReaderView className={readerToolDirection} channelName={roomId} clientId={userId} userId={studentId ? studentId : userId} />
      <ReaderTools className={readerToolDirection} />
      <Modal
        isShowing={isShowing}
        closeModal={closeModal}
        openModal={openModal}
      >{components}</Modal>
      <input type='file' onChange={handleImageFileChange} style={{ display: 'none' }} ref={imageFileHandlerRef} />
      {attendeeNumber && <ActivityWork />}
      {annotationType === AnnotationType.ACTIVITY && <ActivityPanel paramsActivityId={paramsActivityId} paramsTeacherId={paramsTeacherId} />}
      {submenuInfo && <Submenu submenuInfo={submenuInfo} />}
      {areaZoomInteractiveObjects && <AreaZoomController areaZoomInteractiveObjects={areaZoomInteractiveObjects} />}

      <LoginPopup />
      {
        isOnLineDialog && <CheckOnlinePopup />
      }
      {confirmPopupState && <ConfirmPopup />}
    </div>
  );
};

export default Reader;
