import React, { useEffect, useCallback, useState } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import TablePagination from '@material-ui/core/TablePagination';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import dateformat from 'dateformat';
import Button from '@material-ui/core/Button';
import DialogModule from 'components/Dialogs/'
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import { usePreparation } from 'customHooks/preparation';

const useStyles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  paper: {
    width: '100%',
    marginBottom: 5,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  editor: {
    paddingLeft: 5
  }
});

function EnhancedTable(props) {
  const { setAnnotationTabOpen, listData, setInputText } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [, annotationDispatch] = useStore(StoreTypes.annotation);

  const [listName, setListName] = useState("");
  const [listEditorOpen, setListEditorOpen] = useState(false);
  const [listId, setListId] = useState("");
  const [listIndex, setListIndex] = useState(0);
  const [listItem, setListItem] = useState(listData);
  const { copyPreparationAnnotation } = usePreparation();
  const [{ token }] = useStore(StoreTypes.user);

  useEffect(() => {
    if (listData.length > 0) {
      setListItem(listData)
      annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId: listData[0].id });
      setSelectedIndex(0);
    }
  }, [annotationDispatch, listData]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = useCallback((event, index) => {
    setInputText(listData[index].name);
    annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId: listData[index].id });
    setSelectedIndex(index);
  }, [listData, annotationDispatch, setInputText]);


  const ListNameTextField = <TextField
    id="outlined-name"
    label="輸入名稱"
    value={listName}
    onChange={(e) => {
      setListName(e.target.value)
    }}
    margin="normal"
    variant="outlined"
  />

  const ListEditorDialogAction =
    <div>
      <Button onClick={async () => {
        var item = Object.assign({}, listItem[listIndex]);
        setAnnotationTabOpen(false)
        await copyPreparationAnnotation({ annotation: { ...item, name: listName }, token })
        setAnnotationTabOpen(true)
      }}
        color="primary"
        style={{
          border: "solid",
          color: "blue",
          borderWidth: 2,
          marginRight: 5
        }}
      >
        複製
      </Button>
      <Button onClick={() => {

        EventBus.emit({ event: ReaderEvent.DeleteAnnotationEvent, payload: listItem[listIndex] })

        setListName("")
        setListEditorOpen(false)
        setListId("")

        listItem.splice(listIndex, 1)
        setListItem(listItem)
      }}
        color="primary"
        style={{
          border: "solid",
          color: "blue",
          borderWidth: 2,
          marginRight: 5
        }}
      >
        刪除
      </Button>
      <Button
        onClick={() => {
          EventBus.emit({ event: ReaderEvent.SaveAnnotationNameEvent, payload: { annotationId: listId, name: listName } })
          setListName("")
          setListEditorOpen(false)
          setListId("")
          listItem[listIndex].name = listName;
        }}
        color="primary"
        style={{
          border: "solid",
          color: "blue",
          borderWidth: 2,
          marginRight: 5
        }}
      >
        確定
      </Button>
    </div>;

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <List >
          {
            listItem.map((row, index) => {
              return (
                <ListItem
                  selected={selectedIndex === index}
                  key={index}
                  button
                  onClick={event => handleListItemClick(event, index)}
                >
                  <ListItemText primary={`${row.name}(${dateformat(row.updatedAt, 'yyyy-mm-dd HH:MM:ss')})`} />
                  <div
                    onClick={() => {
                      setListName(row.name)
                      setListEditorOpen(true)
                      setListId(row.id)
                      setListIndex(index)
                    }}

                  >
                    <span className={styles.editor}>
                      <Icon type="text" name="pencilAlt" /><span>編輯</span>
                    </span>
                  </div>
                </ListItem>
              );
            })
          }

        </List>
      </div>
      <TablePagination
        rowsPerPageOptions={[]}
        labelRowsPerPage=""
        component="div"
        count={listData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <DialogModule
        open={listEditorOpen}
        setOpen={setListEditorOpen}
        content={ListNameTextField}
        action={ListEditorDialogAction}
      />
    </div>
  );
}

export default EnhancedTable;
