import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';

export const useCreateAnnotation = () => {
    const [{ db }] = useStore(StoreTypes.db);
    const { readAnnotationById } = useReadAnnotations();

    const createAnnotation = useCallback(async ({ id, bookId, type, annotations = [], isDirty = false, pageIndex = 0, name = '', lastSyncedAt = null, updatedAt = Date.now(), marks = [], isDeleted = false, isDoublePageMode = true, canvasSVGObjects = [], isUpdate = true, extendedContentAnnotation = [] }) => {
        await db.annotation.put({ id, bookId, type, annotations, isDirty, pageIndex, name, updatedAt, lastSyncedAt, marks, isDeleted, isDoublePageMode, canvasSVGObjects, isUpdate, extendedContentAnnotation });
        return readAnnotationById({ id });
    }, [db.annotation, readAnnotationById]);

    return createAnnotation;
};

export const useUpdateAnnotation = () => {
    const [{ db }] = useStore(StoreTypes.db);
    const { readAnnotationById } = useReadAnnotations();

    const updateAnnotation = useCallback(async (id, payload) => {
        await db.annotation.update(id, payload);
        return readAnnotationById({ id });
    }, [db.annotation, readAnnotationById]);

    return updateAnnotation;
};

export const useDeleteAnnotation = () => {
    const [{ db }] = useStore(StoreTypes.db);
    const { readAnnotationById } = useReadAnnotations();

    const deleteAnnotation = useCallback(async (id) => {
        await db.annotation.delete(id);
        return readAnnotationById({ id });
    }, [db.annotation, readAnnotationById]);

    const deleteAllAnnotation = useCallback(async () => {
        db.annotation.clear();
        return null;
    }, [db.annotation]);

    return { deleteAnnotation, deleteAllAnnotation };
};

export const useMarkAnnotationSynced = () => {
    const [{ db }] = useStore(StoreTypes.db);

    const markAnnotationSynced = useCallback(async ({ id, lastSyncedAt }) => {
        await db.annotation.update(id, { isDirty: false, lastSyncedAt });
        return db.annotation.get({ id });
    }, [db.annotation]);

    return markAnnotationSynced;
};

export const useMarkAnnotationDeleted = () => {
    const [{ db }] = useStore(StoreTypes.db);

    const markAnnotationDeleted = useCallback(async (id) => {
        await db.annotation.update(id, { isDeleted: true });
        return db.annotation.get({ id });
    }, [db.annotation]);

    return markAnnotationDeleted;
};

export const useReadAnnotations = () => {
    const [{ db }] = useStore(StoreTypes.db);

    const readAnnotationById = useCallback(async ({ id }) => {
        return db.annotation.get({ id });
    }, [db.annotation]);

    const readAnnotations = useCallback(async ({ bookId, annotationType }) => {
        const condition = {};
        if (bookId) {
            condition.bookId = bookId;
        }
        if (annotationType) {
            condition.type = annotationType;
        }
        return db.annotation.where(condition).toArray();
    }, [db.annotation]);

    return {
        readAnnotationById,
        readAnnotations,
    }
};


//painterInfo db
export const useCreateReaderToolSettings = () => {
    const [{ readerToolSettingsDB }] = useStore(StoreTypes.db);
    const readReaderToolSettingsById = useReadReaderToolSettings();

    const createReaderToolSettings = useCallback(async (id, payload) => {
        const existedRecord = await readReaderToolSettingsById(id);
        readerToolSettingsDB.readerToolSettings.put({ ...(existedRecord || { id }), ...payload });
    }, [readReaderToolSettingsById, readerToolSettingsDB.readerToolSettings]);

    return createReaderToolSettings;
};

export const useReadReaderToolSettings = () => {
    const [{ readerToolSettingsDB }] = useStore(StoreTypes.db);
    const readReaderToolSettingsById = useCallback(async (id) => {
        try {
            const existedRecord = await readerToolSettingsDB.readerToolSettings.get({ id });
            return existedRecord
        } catch (e) {
            console.log("no readerToolSettingsDB")
        }
    }, [readerToolSettingsDB.readerToolSettings]);

    return readReaderToolSettingsById
};

export const useReadUserSettings = () => {
    const [{ usersDB }] = useStore(StoreTypes.db);

    const readUserSettings = useCallback(async (userId) => {
        try {
            const existedRecord = await usersDB.userSettings.get({ id: userId });
            return existedRecord;
        } catch (e) {
            console.log("no user settings")
        }
    }, [usersDB.userSettings]);

    return readUserSettings;
};

export const useWriteUserSettings = () => {
    const [{ usersDB }] = useStore(StoreTypes.db);

    const writeUserSettings = useCallback(async (userId, userSettings) => {
        try {
            const existingUserSettings = await usersDB.userSettings.get({ id: userId });
            if (existingUserSettings) {
                await usersDB.userSettings.update(userId, userSettings);
            } else {
                await usersDB.userSettings.put({ id: userId, ...userSettings });
            }
            return usersDB.userSettings.get({ id: userId });
        } catch (e) {
            console.log("no user settings", e);
        }
    }, [usersDB.userSettings]);

    return writeUserSettings;
};
