import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import classnames from 'classnames';
import { Script } from 'components/ExtendedContent/ExtendedContentContainer/layout';
import { scriptLoader } from 'util/scriptLoader'
import * as types from 'constants/actionTypes';
const SynopsisWindow = ({ synopsisInfo, extendedResourceStyle }) => {

    const listRef = useRef();

    const { list } = synopsisInfo;
    const [{ synopsisList, synopsisTab }, extendedContentDispatch] = useStore(
        StoreTypes.extendedContent
    );
    const [isChineseSecondary, setIsChineseSecondary] = useState(false);
    const [isChineseElementary, setIsChineseElementary] = useState(false);

    useEffect(() => {
        if (synopsisList[synopsisTab]) {
            scriptLoader(Script[extendedResourceStyle])
        }
    }, [extendedResourceStyle, synopsisList, synopsisTab])

    useEffect(() => {
        let isChineseSecondary = false, isChineseElementary = false;
        synopsisList.forEach(item => {
            if (item.html.includes('CHINESE SECONDARY')) {
                //  判斷國中國文
                isChineseSecondary = true;
            } else if (item.html.includes('CHINESE ELEMENTARY')) {
                //  判斷國小國文
                isChineseElementary = true;
            }
        });
        setIsChineseSecondary(isChineseSecondary);
        setIsChineseElementary(isChineseElementary);
    }, [synopsisList]);


    const onTabClickHandler = useCallback((index)=>{
      extendedContentDispatch({
        type: types.SET_EXTENDED_CONTENT_SYNOPSIS_TAB,
        payload: {
            synopsisTab: index
        }
      });
    },[extendedContentDispatch])


    useEffect(()=>{
      listRef.current && listRef.current.scrollTo(listRef.current.scrollWidth, 0);
    },[synopsisTab])

    return (
        <div className={classnames(styles.synopsisWindow, {
            [styles.wordSection]: extendedResourceStyle === 'WordSection',
            [styles.ChineseSecondary]: isChineseSecondary,
            [styles.ChineseElementary]: isChineseElementary,
        })}>
            <div className={styles.tab_box}>
                {list && list.map((obj, i) => <div className={classnames(styles.tab, {
                    [styles.active]: synopsisTab === i
                })} key={i} onClick={()=> onTabClickHandler(i)}>{obj.name}</div>)}
            </div>
            <div ref={listRef} className={styles.list}>
                {
                    synopsisList[synopsisTab] && <div dangerouslySetInnerHTML={{ __html: synopsisList[synopsisTab].html }} />
                }
            </div>
        </div>
    )
}

export default SynopsisWindow;
