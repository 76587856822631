import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';

const Footer = () => {
  const menus = [
    {
      title: '歡迎光臨',
      items: [
        {
          text: 'onemall 閱讀器',
          link: ''
        },
        {
          text: '全站活動',
          link: ''
        },
        {
          text: '下載APP',
          link: ''
        },
        {
          text: '常見問題 Q&A',
          link: ''
        },
        {
          text: '我要買書',
          link: ''
        },
        {
          text: '意見回報',
          link: ''
        }
      ]
    },
    {
      title: '關於我們',
      items: [
        {
          text: '公司資訊',
          link: ''
        },
        {
          text: '徵人啟事',
          link: ''
        },
        {
          text: '合作提案',
          link: ''
        },
        {
          text: 'AP推書夥伴',
          link: ''
        }
      ]
    },
    {
      title: '服務條款',
      items: [
        {
          text: '服務條款',
          link: ''
        },
        {
          text: '隱私權條款',
          link: ''
        }
      ]
    },
    {
      title: '社群連結',
      items: [
        {
          text: 'Fackbook',
          link: ''
        },
        {
          text: 'Line',
          link: ''
        }
      ]
    }
  ];

  return (
    <footer>
      <div className={styles.container}>
        {/* <div className={styles.top}>
                    {
                        menus.map((list, index) => {
                            return (
                                <div key={index} className={styles.list_box}>
                                    <h2>{list.title}</h2>
                                    <ul className={styles.list_sub_box}>
                                        {list.items.map((list, i) => <li key={i} className={styles.list}>{list.text}</li>)}
                                    </ul>
                                </div>
                            );
                        })
                    }
                </div> */}
        <div className={styles.connectionInfo}>
          <div>
            <div>
              <h2 className={styles.connectionUs}>聯繫我們</h2>
              <hr className={styles.connectionInfoHR} />
            </div>
          </div>
          <div className={styles.connectionInfoButtons}>
            <div></div>
            <div>
              <a href="https://s.nani.cool/ebookforms" target='_blank' rel='noopener noreferrer'>
                <div className="mb-3 text-muted">
                  <Icon type="text" name="envelope" />
                </div>
                <div>意見回饋</div>
              </a>
            </div>
            <div>
              <a href="https://cdn.nani.cool/download/%E5%8D%97%E4%B8%80%E9%81%A0%E7%AB%AF%E9%80%A3%E7%B7%9A.exe" download>
                <div className="mb-3 text-muted">
                  <Icon type="text" name="tools" />
                </div>
                <div>遠端連線</div>
              </a>
            </div>
            <div></div>
          </div>
          <div className={styles.command}>
            為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為：IE Edge、Google
            Chrome 或 Firefox。
            <br />{' '}
            如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。
          </div>
        </div>
        <div className={styles.bottom}>
          南一教育股份有限公司 Copyright © 2021, oneMall Media, Inc
        </div>
      </div>
    </footer>
  );
};

export default Footer;
