import { useCallback, useRef } from 'react';
import { useReadAnnotations, useMarkAnnotationSynced } from 'customHooks/db';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
const { PreparationRepository } = Repository;

const TaskState = {
    INITIALIZED: 'INITIALIZED',
    EXECUTING: 'EXECUTING',
    EXECUTED: 'EXECUTED'
};

function SyncClassPreparationTask(id, readAnnotationById, markAnnotationSynced, token) {
    this.id = id;
    this.readAnnotationById = readAnnotationById;
    this.markAnnotationSynced = markAnnotationSynced;
    this.token = token;
    this.state = TaskState.INITIALIZED;

    this.execute = async function () {
        this.state = TaskState.EXECUTING;
        const annotation = await this.readAnnotationById({ id });
        const result = await PreparationRepository.updatePreparation({ id, annotation: { preparation: annotation }, token })
        if (result) {
            await this.markAnnotationSynced({ id, lastSyncedAt: result.updatedAt });
        } else {
            console.log('Sync class preparation failed');
        }
        this.state = TaskState.EXECUTED;
    };
};

function SyncSingleAnnotationTask(annoataionInfo, readAnnotationById, markAnnotationSynced, token) {
    this.readAnnotationById = readAnnotationById;
    this.markAnnotationSynced = markAnnotationSynced;
    this.token = token;
    this.state = TaskState.INITIALIZED;

    this.execute = async function () {
        this.state = TaskState.EXECUTING;
        const result = await PreparationRepository.updateSingleAnnotatoin({ annoataionInfo, token })
        if (result) {

        } else {
            console.log('Sync class preparation failed');
        }
        this.state = TaskState.EXECUTED;
    };
};

export const useSyncClassPreparationDispatcher = () => {
    const { readAnnotationById } = useReadAnnotations();
    const markAnnotationSynced = useMarkAnnotationSynced();
    const [{ token }] = useStore(StoreTypes.user);
    const queue = useRef([]);

    const execute = useCallback(async () => {
        if (queue.current[0] && queue.current[0].state === TaskState.INITIALIZED) {
            await queue.current[0].execute();
            queue.current = queue.current.filter(task => task.state !== TaskState.EXECUTED);
            if (queue.current.length > 0) {
                await execute();
            }
        }
    }, []);

    const syncClassPreparation = useCallback(async (id) => {
        const newTask = new SyncClassPreparationTask(id, readAnnotationById, markAnnotationSynced, token);
        let skipTask = false;
        for (let task of queue.current) {
            if (task.id === newTask.id && task.state.INITIALIZED) {
                skipTask = true;
                break;
            }
        }
        if (!skipTask) {
            queue.current.push(newTask);
        }
        await execute();
    }, [execute, markAnnotationSynced, readAnnotationById, token]);

    const syncSinglePreparation = useCallback(async (annoataionInfo) => {
        const newTask = new SyncSingleAnnotationTask(annoataionInfo, readAnnotationById, markAnnotationSynced, token);
        let skipTask = false;
        for (let task of queue.current) {
            if (task.id === newTask.id && task.state.INITIALIZED) {
                skipTask = true;
                break;
            }
        }
        if (!skipTask) {
            queue.current.push(newTask);
        }
        await execute();
    }, [execute, markAnnotationSynced, readAnnotationById, token]);

    return { syncClassPreparation, syncSinglePreparation };
};
