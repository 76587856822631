import React from 'react';
import useSetState from 'customHooks/setState';
import AudioControlsButton from 'components/common/AudioControlsButton';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';

const { ExtendedResourceRepository } = Repository;
const HearingDraft = ({ data, defaultTab }) => {
  const { contents } = data;
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ fontSize }] = useStore(StoreTypes.extendedContent);
  const [{ tabIndex }, setState] = useSetState({
    tabIndex: defaultTab
  });

  return (
    <div className={classnames(styles.hearingDraft, styles[fontSize])}>
      <div className={styles.main}>
        <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
          bookId,
          pathName: contents[tabIndex].contentSound
        })} />
        <div className={styles.topic}>
          <div className={styles.title}>
            <img src={ExtendedResourceRepository.getDataSrc({ bookId, pathName: contents[tabIndex].topic })} alt="" />
          </div>
          <div className={styles.content}>
            {
              contents[tabIndex].content.map((list, index) => {
                return (
                  <div
                    className={styles.list}
                    key={list}
                    dangerouslySetInnerHTML={{ __html: list }}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
      <div className={classnames(styles.tab)}>
        {
          contents.map((item, index) => <div className={classnames(styles.list, {
            [styles.active]: tabIndex === index
          })} key={index} onClick={() => setState({ tabIndex: index })}>{index + 1}</div>)
        }
      </div>
    </div>
  );
};

export default HearingDraft;
