import React, { useEffect } from 'react';
import { ExtendedContentContainer, ExtendedContentType } from 'components/ExtendedContent/ExtendedContentContainer';
import WordLearningGrid from 'components/WordLearningGrid';
import { WordLearningContentWrapper } from 'components/WordLearningGrid/WordLearningContent';
import WordContentList from 'components/WordContentList';
import WordRadical from 'components/WordRadical';
import { ExtensionType } from 'constants/wordGrid';
import { EventBus, EventBusType } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import AnchorAudioButton from 'components/WordExample/AnchorAudioButton';
import * as actionTypes from 'constants/actionTypes';
import classnames from 'classnames'
import styles from './index.module.scss';

const WORDING_MAP = {
  meaningSet: '字義教學',
  wordsSet: '語詞教學',
  idiomSet: '生字成語',
  glyphSet: '字音字形'
};

const convertIdiomResource = data =>
  Object.entries(data).map(([key, item]) => ({ label: key, value: item.RelatedWords[0], relatedWords: item.RelatedWords }))

const convertWordGridResource = data => data.map(item => ({ label: item.Name, value: item.Name, relatedWords: [item.Name] }))


const MeaningContent = ({ data, list }) => {
  const [{
    word,
    extensionType
  }, wordGridDispatch] = useStore(StoreTypes.wordGrid);

  const onListChangeHandler = ({ word, relatedWords }) => {

    wordGridDispatch({
      type: actionTypes.SET_SUBCONTENT_SHOW,
      payload: {
        isShowSubContent: true,
        extensionType
      }
    })


    word && wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word
      }
    })


    wordGridDispatch({
      type: actionTypes.SET_RELATED_WORDS,
      payload: {
        relatedWords
      }
    })

  }

  useEffect(() => {
    const current = list.filter(item => item.relatedWords.includes(word));
    if (current.length > 0) {
      onListChangeHandler({ relatedWords: current[0].relatedWords })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.meaningContentWrapper}>
      <WordLearningContentWrapper
        data={data}
      />
      <WordContentList
        word={word}
        className={styles.wordContentList}
        data={list}
        onChange={onListChangeHandler}
      />
    </div>
  )
}


const WordLearningGridContainer = ({
  wordGridResource,
  defaultWord,
  idiomResource,
  wordsResource
}) => {
  const [{
    isShowRadicalContent,
    radicalData,
    isShowSubContent,
    word,
    relatedWords,
    extensionType,
    extensionData,
    wordsData,
  }, wordGridDispatch] = useStore(StoreTypes.wordGrid);
  const clearCanvas = () => {
    const eventBusType = EventBusType.ExtendedContent;
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent
    });
  }

  const subContentCloseHandler = () => {
    wordGridDispatch({ type: actionTypes.SET_SUBCONTENT_SHOW, payload: { isShowSubContent: false } })
  }

  const swtichContent = type => {
    wordGridDispatch({
      type: actionTypes.SET_SUBCONTENT_SHOW,
      payload: {
        isShowSubContent: true,
        extensionType: type
      }
    })
    clearCanvas();
  }

  const onRadicalCloseHandler = () => {
    wordGridDispatch({ type: actionTypes.SET_RADICAL_CONTENT_SHOW, payload: { isShowRadicalContent: false, radicalData: null } })
  }

  const onScrollToAnchor = tabIndex => {
    wordGridDispatch({
      type: actionTypes.SET_CHANGE_TAB_INDEX,
      payload: {
        tabIndex
      }
    })
  }

  useEffect(() => {
    wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word: defaultWord
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ExtendedContentContainer extendedContentType={ExtendedContentType.WordGrid}>
        <WordLearningGrid dataList={wordGridResource} defaultWord={defaultWord} />
      </ExtendedContentContainer>
      {
        isShowSubContent && (
          <ExtendedContentContainer
            extendedContentType={ExtendedContentType.WordGridSubContent}
            onClose={subContentCloseHandler}
          >
            <div className={styles.wordGridConentContainer}>
              {
                extensionType === ExtensionType.MeaningSet && (
                  <MeaningContent data={extensionData[ExtensionType.MeaningSet]} list={convertWordGridResource(wordsData.filter(item => item.MeaningSet.length > 0))} />
                )
              }
              {
                extensionType === ExtensionType.WordsSet && (
                  <MeaningContent data={extensionData[ExtensionType.WordsSet]} list={convertIdiomResource(wordsResource)} />
                )
              }
              {
                extensionType === ExtensionType.IdiomSet && (
                  <MeaningContent data={extensionData[ExtensionType.IdiomSet]} list={convertIdiomResource(idiomResource)} />
                )
              }
              {
                extensionType === ExtensionType.GlyphSet && (
                  <MeaningContent data={extensionData[ExtensionType.GlyphSet]} list={convertWordGridResource(wordsData.filter(item => item.GlyphSet.length > 0))} />
                )
              }
              <div className={styles.setup}>
                {
                  extensionType === 'wordsSet' && <div className={styles.breakAnchor}>
                    {
                      extensionData.wordsSet.map((item, index) => {
                        switch (index) {
                          case 0:
                            return (
                              <>
                                <AnchorAudioButton 
                                  audioSrc={item.CommentAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                                >
                                  語詞解釋
                                </AnchorAudioButton>
                                <AnchorAudioButton 
                                  audioSrc={item.ExampleAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                                >
                                  語詞例句
                                </AnchorAudioButton>
                              </>
                            )
                          default:
                            return (
                              <>
                                <AnchorAudioButton
                                  audioSrc={item.CommentAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                                >
                                  延伸解釋{index}
                                </AnchorAudioButton>
                                <AnchorAudioButton 
                                  audioSrc={item.ExampleAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                                >
                                  延伸例句{index}
                                </AnchorAudioButton>
                              </>
                            )
                        }
                      })
                    }
                  </div>
                }
                {
                  extensionType === 'idiomSet' && <div className={styles.breakAnchor}>
                    {
                      extensionData.idiomSet.map((item, index) => {
                        switch (index) {
                          case 0:
                            return (
                              <>
                                <AnchorAudioButton 
                                  audioSrc={item.CommentAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                                >
                                  成語解釋
                                </AnchorAudioButton>
                                <AnchorAudioButton 
                                  audioSrc={item.ExampleAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                                >
                                  成語例句
                                </AnchorAudioButton>
                              </>
                            )
                          default:
                            return (
                              <>
                                <AnchorAudioButton
                                  audioSrc={item.CommentAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                                >
                                  補充解釋{index}
                                </AnchorAudioButton>
                                <AnchorAudioButton 
                                  audioSrc={item.ExampleAudio}
                                  onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                                >
                                  補充例句{index}
                                </AnchorAudioButton>
                              </>
                            )
                        }
                      })
                    }
                  </div>
                }
                <div className={classnames(styles.controls, {
                  [styles.notWordsSet]: extensionType !== 'wordsSet'
                })}>
                  <div className={styles.words}>
                    {
                      relatedWords.map(item => (
                        <div className={styles.word}>{item}</div>
                      ))
                    }
                  </div>
                  <div className={styles.extensionButtons}>
                    {
                      Object.values(ExtensionType).map(item => {
                        const isDisabled = extensionData[item] && extensionData[item].length < 1;
                        return (
                          <div key={item} className={classnames(styles.button,
                            {
                              [styles.active]: item === extensionType,
                              [styles.disabled]: isDisabled
                            })
                          }
                            onClick={() => {
                              !isDisabled && swtichContent(item)
                            }}>
                            {WORDING_MAP[item]}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </ExtendedContentContainer>
        )
      }
      {
        isShowRadicalContent && (
          <ExtendedContentContainer onClose={onRadicalCloseHandler}>
            <WordRadical data={radicalData} />
          </ExtendedContentContainer>
        )
      }
    </>
  )
}

export const WordGridModalComponent = ({
  wordGridResource,
  defaultWord,
  wordsResource,
  idiomResource
}) => {
  return (
    <WordLearningGridContainer
      wordGridResource={wordGridResource}
      defaultWord={defaultWord}
      wordsResource={wordsResource}
      idiomResource={idiomResource}
    />
  )
};
