
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';

function FilePreparationRepository() { }

FilePreparationRepository.prototype.getPreparationList = async ({
    bookId,
    token
}) => {
    return [];
};

FilePreparationRepository.prototype.getPreparationById = async ({
    id,
    token
}) => {
    return null;
};

FilePreparationRepository.prototype.getPreparationAnnotations = async ({
    id,
    token,
}) => {
    return await new Promise(resolve => {
        EventBus.emit({
            event: ReaderEvent.GetLocalAnnotationEvent,
            payload: {
                id,
                callback: resolve,
            }
        })
    });
};

FilePreparationRepository.prototype.updatePreparation = async ({
    id,
    obj,
    token
}) => {
    return null;
};

FilePreparationRepository.prototype.updateSingleAnnotatoin = async ({
    annoataionInfo,
    token
}) => {
    return null;
};

FilePreparationRepository.prototype.copyPreparationAnnotations = async ({
    annotation,
    token
}) => {
    return new Promise((resolve) => {
        EventBus.emit({ event: ReaderEvent.CopyAnnotationEvent, payload: annotation })
        setTimeout(() => resolve(), 1000)
    })
};

export default new FilePreparationRepository();
