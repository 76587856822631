import React, { useEffect, useCallback, useRef } from 'react';
import ControlButton from 'components/common/Player/ControlButton';
import { useBoolean } from 'customHooks/boolean';
import useMouse from 'customHooks/mouse';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const VolumeBar = ({
  state,
  controls,
  onChange,
  className,
  hoverable = true,
  width = 100,
  onVolumeBarActiveStateChange = () => { }
}) => {
  const [isShowVolumeBar, { toggle: setVolumeBarToggle, setFalse: setVolumeBarDeactive, setTrue: setVolumeBarActive }] = useBoolean();

  const volumeBarRef = useRef();
  const { elX, elW } = useMouse(volumeBarRef);

  const changeHandler = useCallback(e => {
    onChange(elX / elW);
  }, [elW, elX, onChange]);

  const onClickHandler = useCallback(() => {
    if (hoverable) {
      return (state.volume > 0 && !state.muted) || !state.muted
        ? controls.mute()
        : controls.unmute()
    } else {
      setVolumeBarToggle();
    }

  }, [controls, hoverable, setVolumeBarToggle, state.muted, state.volume]);

  const onMouseEnterHandler = useCallback(() => {
    hoverable && setVolumeBarActive();
  }, [hoverable, setVolumeBarActive]);

  const onMouseLeaveHandler = useCallback(() => {
    hoverable && setVolumeBarDeactive();
  }, [hoverable, setVolumeBarDeactive]);

  useEffect(() => {
    onVolumeBarActiveStateChange(isShowVolumeBar);
  }, [isShowVolumeBar, onVolumeBarActiveStateChange])


  return (
    <div className={classnames(styles.volumeBarWrapper, className)}>
      <ControlButton
        onClick={() => onClickHandler()}
        onMouseEnter={() => onMouseEnterHandler()}
        onMouseLeave={() => onMouseLeaveHandler()}
      >
        {(state.volume === 0 || state.muted) && (
          <Icon type="text" name="volumeMute" />
        )}
        {state.volume > 0 && state.volume < 0.7 && !state.muted && (
          <Icon type="text" name="volumeDown" />
        )}
        {state.volume >= 0.7 && !state.muted && (
          <Icon type="text" name="volumeUp" />
        )}
      </ControlButton>

      <div
        ref={volumeBarRef}
        style={{ width: isShowVolumeBar ? `${width}px` : 0 }}
        className={classnames(styles.volumeBar, {
          [styles.active]: isShowVolumeBar
        })}
        onMouseEnter={() => onMouseEnterHandler()}
        onMouseLeave={() => onMouseLeaveHandler()}
        onClick={e => changeHandler(e)}
      >
        <div
          className={styles.volumeUsage}
          style={{ width: `${(state.volume / 1) * 100}%` }}
        />
      </div>
    </div >
  );
};

export default VolumeBar;
