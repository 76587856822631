import { PlatformType } from 'constants/platformType';

export const getPlatform = () => {
  let platform = PlatformType.Web;

  if(window.electron) {
      return PlatformType.Electron;
  }

  if(process.env.REACT_APP_PLATFORM === PlatformType.Ios) {
      return PlatformType.Ios;
  }

  if(process.env.REACT_APP_PLATFORM === PlatformType.Android) {
      return PlatformType.Android;
  }
  return platform;
}
