import React, { useRef, useEffect, useCallback } from 'react';
import { useBoolean } from 'customHooks/boolean';
import useSetState from 'customHooks/setState';
import Icon from 'components/Icon';
import AudioControlsButton from 'components/common/AudioControlsButton';
import VocabularyInfo from './VocabularyInfo';
import { useStore, StoreTypes } from 'context';
import { groupByArrayToMap, convertArrayToMap } from 'util/array';
import { tabType } from 'constants/vocabularyExample';
import TranslationControlsButton from 'components/common/TranslationControlsButton';
import classnames from 'classnames';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const COUNT = 4;

const defaultContent = {
  word: '',
  wordSound: '',
  wordTranslation: '',
  KK: '',
  example: '',
  exampleSound: '',
  exampleTranslation: '',
  image: '',
  plus: [{
    plusContent: '',
    antonym: '',
    synonym: '',
    content: '',
    example: '',
    exampleSound: '',
    exampleTranslation: '',
    extra: {
      content: '',
      example: [],
      exampleSound: '',
      exampleTranslation: []
    }
  }],
  extra: [{
    content: '',
    example: '',
    exampleSound: '',
    exampleTranslation: ''
  }]
};

const defaultTabContent = {
  [tabType.vocabulary]: { value: tabType.vocabulary, label: '單字' },
  [tabType.example]: { value: tabType.example, label: '例句' },
  [tabType.plus]: { value: tabType.plus, label: '補充' },
  [tabType.extra]: { value: tabType.extra, label: '一字多義' }
};

const PlusExtraExampleComponent = ({
  audioSrc,
  data
}) => (
  <div className={styles.example}>
    <AudioControlsButton
      className={styles.exampleSound}
      src={audioSrc} />
    <span className={styles.exampleIcon}>例</span>
    <div className={styles.content}>
      {Array.isArray(data) ? (
        data.map(item =>
          <p key={item} dangerouslySetInnerHTML={{ __html: item }} />
        )
      ) : (
          <p dangerouslySetInnerHTML={{ __html: data }} />
        )}
    </div>
  </div>
)

const  PlusExtraExampleTranslationComponent = ({ data }) => (
  <div className={styles.exampleTranslation}>
    {
      Array.isArray(data) ? (
        data.map(item => (
          <p key={item} dangerouslySetInnerHTML={{ __html: item }} />
        ))
      ) : (
          <p dangerouslySetInnerHTML={{ __html: data }} />
        )
    }
  </div>
)

const PlusExtraComponent = ({
  bookId,
  data: {
    extraContent,
    plusContent,
    antonym,
    synonym,
    content,
    example,
    exampleSound,
    exampleTranslation,
  }
}) => (
    <div className={styles.plusContent}>
      {
        content && (
          <div>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        )
      }
      {
        plusContent && (
          <div>
            <span className={styles.contentIcon}>補</span>
            <span dangerouslySetInnerHTML={{ __html: plusContent }} />
          </div>
        )
      }
      {
        extraContent && (
          <div>
            <span 
              className={classnames(styles.contentIcon, styles.multiplyMeaning)}
            >一字多義</span>
            <span dangerouslySetInnerHTML={{ __html: extraContent }} />
          </div>
        )
      }
      {
        antonym && (
          <div>
            <span className={styles.contentIcon}>反</span>
            <span dangerouslySetInnerHTML={{ __html: antonym }} />
          </div>
        )
      }
      {
        synonym && (
          <div>
            <span className={styles.contentIcon}>同</span>
            <span dangerouslySetInnerHTML={{ __html: synonym }} />
          </div>
        )
      }
      {
        example && (
          <PlusExtraExampleComponent 
            audioSrc={ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: exampleSound
            })}
            data={example}
          />
        )
      }
      {
        exampleTranslation && (
          <PlusExtraExampleTranslationComponent data={exampleTranslation}/> 
        )
      }
      {/* {
        extra && (
          <PlusExtraMultiplyMeaningComponent 
            audioSrc={ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: extra.exampleSound
            })}
            data={extra}
          />
        )
      } */}
    </div>
  );

const VocabularyExample = ({ data: { contents = [] }, defaultWord }) => {
  const [
    {
      currentVoca,
      vocaCurrentPage,
      vocaDataMap,
      vocaPageMap,
      vocaTotalPage,
      tabDataArr,
      currentTabValue,
      currentTabIndex
    },
    setState
  ] = useSetState({
    vocaCurrentPage: 1,
    vocaPageMap: null,
    vocaTotalPage: 0,
    currentVoca: defaultWord || contents[0].word,
    tabDataArr: [
      defaultTabContent[tabType.vocabulary],
      defaultTabContent[tabType.example]
    ],
    currentTabValue: tabType.vocabulary,
    currentTabIndex: 0
  });

  const [{ bookId }] = useStore(StoreTypes.books);
  const tabsRef = useRef();

  const [isShowTW, { toggle: toggleShowTW, setFalse: hideTW }] = useBoolean();
  const [{ fontSize }] = useStore(StoreTypes.extendedContent);

  const content = Object.assign(
    {},
    defaultContent,
    vocaDataMap ? vocaDataMap[currentVoca] : {}
  );

  const tabLimitedIndex = index => {
    const max = tabDataArr.length - 1;
    return Math.max(0, Math.min(index, max));
  };

  const tabChangeHandler = i => {
    const index = tabLimitedIndex(i);
    setState({
      currentTabValue: tabDataArr[index].value,
      currentTabIndex: index
    });
  };

  const vocaChangeHandler = word => {
    hideTW();
    setState({ currentVoca: word });
  };

  const vocaPageChangeHandler = page => {
    const max = vocaTotalPage;
    setState({ vocaCurrentPage: Math.max(1, Math.min(page, max)) });
  };

  const generatesContentData = useCallback((contents, count) => {
    const data = contents.map((content, index) => ({
      ...content,
      page: Math.floor(index / count) + 1
    }));
    const pageMap = groupByArrayToMap(data, 'page');
    const dataMap = convertArrayToMap(data, 'word');
    const totalPage = Math.ceil(contents.length / count);
    return {
      dataMap,
      pageMap,
      totalPage
    };
  }, []);

  useEffect(() => {
    const { dataMap, pageMap, totalPage } = generatesContentData(
      contents,
      COUNT
    );
    setState({
      vocaDataMap: dataMap,
      vocaPageMap: pageMap,
      vocaTotalPage: totalPage,
      vocaCurrentPage: dataMap[currentVoca].page
    });
  }, [contents, setState, generatesContentData, currentVoca]);

  useEffect(() => {
    const newTabContent = Object.assign({}, defaultTabContent, {
      plus: content.plus.length > 0 ? defaultTabContent.plus : null,
      extra: content.extra.length > 0 ? defaultTabContent.extra : null
    });
    const tabDataArr = Object.values(newTabContent).filter(item => item);
    setState({ tabDataArr });
  }, [currentVoca, content.plus.length, content.extra.length, setState]);

  const isVocaDisabled = useCallback(
    word => {
      const { plus, extra } = vocaDataMap[word];
      if (currentTabValue === 'plus' && plus.length < 1) return true;
      if (currentTabValue === 'extra' && extra.length < 1) return true;
      return false;
    },
    [vocaDataMap, currentTabValue]
  );

  return (
    <>
      {currentTabValue === tabType.example && (
        <TranslationControlsButton
          className={styles.controlsButton}
          active={isShowTW}
          content="中文"
          onClick={toggleShowTW}
        />
      )}
      <div className={classnames(styles.vocabularyExample, styles[fontSize])}>
        <div className={styles.tabPanelWrapper}>
          {currentTabValue === tabType.vocabulary && (
            <section className={classnames(styles.tabPanel, styles.vocabulary)}>
              <div className={styles.left}>
                <div className={styles.word}>{content.word}</div>
                <VocabularyInfo
                  kkContent={content.KK}
                  partOfSpeech={content.partOfSpeech}
                />
                <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
                  bookId,
                  pathName: content.wordSound
                })} />
              </div>
              <div className={styles.right}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${ExtendedResourceRepository.getDataSrc(
                      {
                        bookId,
                        pathName: content.image
                      }
                    )})`
                  }}
                ></div>
              </div>
            </section>
          )}
          {currentTabValue === tabType.example && (
            <section className={classnames(styles.tabPanel, styles.example)}>
              <div className={styles.title}>
                <div className={styles.info}>
                  <AudioControlsButton
                    className={styles.audioButton}
                    src={ExtendedResourceRepository.getDataSrc({
                      bookId,
                      pathName: content.wordSound
                    })}
                  />
                  <div className={styles.word}>
                    <div className={styles.vocabularyWord}>
                      <span className={styles.vocaWord}>{content.word}</span>
                    </div>
                    <div className={styles.vocaInfo}>
                      <VocabularyInfo
                        className={styles.vocabularyInfo}
                        kkContent={content.KK}
                        partOfSpeech={content.partOfSpeech}
                      />
                      <div
                        className={classnames(styles.tw, {
                          [styles.active]: isShowTW
                        })}
                      >
                        {content.wordTranslation}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.exampleSentence}>
                  <AudioControlsButton
                    className={styles.audioButton}
                    src={ExtendedResourceRepository.getDataSrc({
                      bookId,
                      pathName: content.exampleSound
                    })}
                  />
                  <div className={styles.exampleSentenceContent}>
                    {Array.isArray(content.example) ? (
                      content.example.map(item =>
                        <p key={item} dangerouslySetInnerHTML={{ __html: item }} />
                      )
                    ) : (
                        <p dangerouslySetInnerHTML={{ __html: content.example }} />
                      )}
                    {Array.isArray(content.exampleTranslation) ? (
                      content.exampleTranslation.map(item => (
                        <p
                          className={classnames(styles.tw, {
                            [styles.active]: isShowTW
                          })}
                        >
                          {item}
                        </p>
                      ))
                    ) : (
                        <p
                          className={classnames(styles.tw, {
                            [styles.active]: isShowTW
                          })}
                        >
                          {content.exampleTranslation}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </section>
          )}
          {currentTabValue === tabType.plus && (
            <section className={classnames(styles.tabPanel, styles.plus)}>
              <div className={styles.content}>
                {content.plus.map(item => (
                  typeof item === 'object' ?
                    <PlusExtraComponent data={item} bookId={bookId} /> :
                    <div
                      key={item}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                ))}
              </div>
            </section>
          )}

          {currentTabValue === tabType.extra && (
            <section className={classnames(styles.tabPanel, styles.extra)}>
              <div className={styles.content}>
                {content.extra.map(item => (
                  typeof item === 'object' ?
                    <PlusExtraComponent data={item} bookId={bookId} /> :
                    <div
                      key={item}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                ))}
              </div>
            </section>
          )}
        </div>

        <div className={styles.controlBar}>
          <div className={styles.tabSelector}>
            <div className={styles.tabs} ref={tabsRef}>
              {tabDataArr.map(
                (item, index) =>
                  item.value && (
                    <div
                      key={index}
                      className={classnames(styles.tab, {
                        [styles.active]: item.value === currentTabValue
                      })}
                      onClick={() => tabChangeHandler(index)}
                    >
                      {item.label}
                    </div>
                  )
              )}
            </div>
            <div className={styles.ctrlButtonGroup}>
              <div
                title={tabDataArr[tabLimitedIndex(currentTabIndex - 1)].label}
                className={classnames(styles.ctrl, styles.left, {
                  [styles.disabled]: tabDataArr[0].value === currentTabValue
                })}
                onClick={() => tabChangeHandler(currentTabIndex - 1)}
              >
                <Icon type="text" name="chevronLeft" />
              </div>
              <div
                title={tabDataArr[tabLimitedIndex(currentTabIndex + 1)].label}
                className={classnames(styles.ctrl, styles.right, {
                  [styles.disabled]:
                    tabDataArr[tabDataArr.length - 1].value === currentTabValue
                })}
                onClick={() => tabChangeHandler(currentTabIndex + 1)}
              >
                <Icon type="text" name="chevronRight" />
              </div>
            </div>
          </div>
          <div className={styles.vocaSelector}>
            <div
              className={classnames(styles.ctrl, styles.left, {
                [styles.hide]: vocaCurrentPage === 1
              })}
              onClick={() => vocaPageChangeHandler(vocaCurrentPage - 1)}
            >
              <Icon type="text" name="chevronLeft" />
            </div>
            <div className={styles.contentWrapper}>
              {vocaPageMap &&
                vocaPageMap[vocaCurrentPage].map(item => (
                  <div
                    className={classnames(styles.voca, {
                      [styles.active]: currentVoca === item.word,
                      [styles.disabled]: isVocaDisabled(item.word)
                    })}
                    onClick={() => vocaChangeHandler(item.word)}
                    key={item.word}
                  >
                    {item.word}
                  </div>
                ))}
            </div>
            <div
              className={classnames(styles.ctrl, styles.right, {
                [styles.hide]: vocaCurrentPage === vocaTotalPage
              })}
              onClick={() => vocaPageChangeHandler(vocaCurrentPage + 1)}
            >
              <Icon type="text" name="chevronRight" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VocabularyExample;
