export const parseZhuyinOnly = (html, rangeNode) => {
  let fragment = new DOMParser().parseFromString(html, 'text/html')
  const fragmentInRangeCildNode = fragment.querySelector(rangeNode).childNodes;
  const fragmentInRange = fragment.querySelector(rangeNode);

  const elArr = [];
  if (fragmentInRangeCildNode.length > 0) {
    for (let i = 0; i < fragmentInRangeCildNode.length; i++) {
      // 第一層，根據ruby標籤裡面，判斷是否有rt標籤
      if (fragmentInRangeCildNode[i].tagName.toLowerCase() === 'ruby') {
        // 去除中文字
        fragmentInRangeCildNode[i].removeChild(fragmentInRangeCildNode[i].firstChild);
      }

      // 子層還有中文要刪除時 TODO: 改用遞迴寫法
      const rubyNodes = fragmentInRangeCildNode[i].querySelectorAll('ruby');
      if (rubyNodes.length > 0) {
        for (let i = 0; i < rubyNodes.length; i++) {
          if (rubyNodes[i].tagName.toLowerCase() === 'ruby') {
            // 去除中文字
            rubyNodes[i].removeChild(rubyNodes[i].firstChild);
          }
        }
      }

      elArr.push(fragmentInRangeCildNode[i]);
    }
  }

  fragmentInRange.innerHTML = '';
  elArr.forEach(item => {
    fragment.querySelector(rangeNode).appendChild(item)
  })


  return fragment.querySelector('body').innerHTML;
}