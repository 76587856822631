import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styles from './index.module.scss';

export default function Tags({ title, optionsData = [], onChange }) {
  return (
    <div className={styles.autocomplete}>
      <Autocomplete
        options={optionsData}
        noOptionsText={'請清除目前選項後重新選取'}
        getOptionLabel={(option) => option.label}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={title}
          />
        )}
      />
    </div>
  );
}
