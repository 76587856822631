import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useWhiteboardCommand = () => {
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ canvasSVGObject }) => {
        try {
            const component = ModalComponentGenerator.generate(ModalComponentType.Whiteboard, { canvasSVGObject });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('WhiteboardCommand execute error', e);
        }
    }, [openModal, setModalComponents]);

    return { execute };
};
