import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFetchBooks } from 'customHooks/book';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { Roles } from 'constants/role';
import DialogModule from 'components/Dialogs/'
import { AnnotationActivityTab } from 'components/AnnotationActivityTab/';
import { AnnotationType } from 'constants/annotationTypes';
import Repository from 'repositories/Repository';
import Card from './Card';
import Pagination from 'components/Pagination';
import Autocomplete from 'components/Autocomplete';
import useSetState from 'customHooks/setState';

import { useUpdateDisplayLanguage, useUpdateUserSettings, useUpdateUserFavorites } from 'customHooks/userSetting';
import { UserSettingsAPI } from 'api/UserSettingsAPI';
import { usePreparation } from 'customHooks/preparation';

import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LoginPopup from 'components/Login/Popup';
import { useReadAnnotations } from 'customHooks/db';
import { scrollTo } from 'util/scrollTo';
import { educationalSystemTable, gradeTable, subjectTable } from 'constants/optionTable';
import Loading from 'components/Loading';

const TabAnnotationType = [
    AnnotationType.CLASS_PREPARATION,
    AnnotationType.ACTIVITY
];

export const BookStore = () => {
    const [{ progress }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ bookList }, bookDispatch] = useStore(StoreTypes.books);
    const [{ role, token, isLogin }] = useStore(StoreTypes.user);
    const [{ annotationId }, annotationDispatch] = useStore(StoreTypes.annotation);
    const [selectBookID, setSelectBookID] = useState("");
    const { isLoading, getBookList, getBookTags } = useFetchBooks();
    const [inputDialogOpen, setInputDialogOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [tab, setTab] = React.useState(0);
    const [isAnnotationTabOpen, setAnnotationTabOpen] = useState(false);
    const [progressOpen, setProgressOpen] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [progressText, setProgressText] = useState("讀取頁面中...");
    const [loadingText, setLoadingText] = useState("讀取備課清單中...");
    const router = useStore(StoreTypes.router);
    const { BookContentRepository } = Repository;
    const { updateDisplayLanguage } = useUpdateDisplayLanguage();
    const updateUserSettings = useUpdateUserSettings();
    const updateUserFavorites = useUpdateUserFavorites();
    const { updatePreparationAnnotation } = usePreparation();
    // tag 資料
    const [bookTags, setBookTags] = useState({});
    const [{ books }] = useStore(StoreTypes.settings);
    const [{ SchoolYear, EducationalSystem, Subject, Grade }, setTag] = useSetState({
        SchoolYear: [],
        EducationalSystem: [],
        Subject: [],
        Grade: []
    });

    const [addShelfDialog, setShelfDialog] = useState({
        state: false,
        content: ''
    });
    const [pageState, setPageState] = useState({
        currentPage: 1
    })
    // tag 初始選項
    const [initTag, setInitTag] = useState({});
    // 篩選 tag
    const [filterTag, setFilterTag] = useState([]);
    // 篩選 tag 順序
    const [filterOrderTag, setFilterOrderTag] = useState([]);
    const root = document.getElementById('root');

    useEffect(() => {
        if (!isLogin) return;
        (async () => {
            //const userSettings = await UserSettingsAPI.getUserSettings(token);
            const favorites = await UserSettingsAPI.getUserFavorites(token);

            // if (userSettings.status === 'success') {
            //     await updateUserSettings(userSettings.data, false);
            // }

            if (favorites.status === 'success') {
                await updateUserFavorites(favorites.data, false);
            }
            updateDisplayLanguage();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, updateDisplayLanguage, updateUserSettings]);

    useEffect(() => {
        readerDispatch({ type: types.SET_BOOK_PAGE_INDEX, pageIndex: 0 });
        annotationDispatch({ type: types.RESET_ANNOTATION_INFO });
    }, [annotationDispatch, readerDispatch]);

    useEffect(() => {
        (async () => {
            const tags = await getBookTags();
            const dataMap = {
                Grade: tags.filter(tag => tag.category === 'Grade'),
                Subject: tags.filter(tag => tag.category === 'Subject'),
                EducationalSystem: tags.filter(tag => tag.category === 'EducationalSystem'),
                SchoolYear: tags.filter(tag => tag.category === 'SchoolYear'),
            }

            setInitTag(dataMap);
            setBookTags(dataMap);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goBook = (bookId, interactiveObjectId) => {
        router.history.push({ pathname: `/${bookId}`, search: interactiveObjectId ? `?interactiveObjectId=${interactiveObjectId}` : '' });
    };
    const openBook = ({ bookId, interactiveObjectId }) => {
        setSelectBookID(bookId);
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        if (role === Roles.EDITOR) {
            annotationDispatch({
                type: types.UPDATE_ANNOTATION_INFO,
                annotationId: interactiveObjectId,
                annotationType: AnnotationType.INTERACTIVE_OBJECT
            });

            readerDispatch({
                type: types.SET_FULL_WIDTH_INFO,
                fullWidthInfo: { mode: false }
            });
            goBook(bookId, interactiveObjectId);
            // } else if (role === Roles.GUEST) {
            //     EventBus.emit({
            //         event: ReaderEvent.CreateAndEnterAnnotationEvent,
            //         payload: {
            //             bookId,
            //             annotationType: AnnotationType.GUEST,
            //             name: "未命名",
            //             callback: (err) => {
            //                 if (!err) {
            //                     goBook(bookId);
            //                 }
            //             }
            //         }
            //     });
        } else {
            setAnnotationTabOpen(true);
        }
    };

    const getAnnotationType = ({ role, tab }) => {
        switch (role) {
            case Roles.TEACHER:
            case Roles.STUDENT:
            case Roles.PARENT:
                return TabAnnotationType[tab]
            default:
                return AnnotationType.GUEST
        }
    }

    const confirmHandler = (annotationId) => async () => {
        if (annotationId) {
            setProgressOpen(true)
            await updatePreparationAnnotation({ id: annotationId, token })
            EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: { progress: 100 } })
        }
        setProgressText("讀取完成")
        setTimeout(() => {
            setProgressOpen(false)
            EventBus.emit({
                event: ReaderEvent.CreateAndEnterAnnotationEvent,
                payload: {
                    bookId: selectBookID,
                    annotationType: getAnnotationType({ role, tab }),
                    annotationId,
                    name: (inputText === "") ? "未命名" : inputText,
                    callback: (err) => {
                        if (!err) {
                            setState(prev => {
                                return {
                                    ...prev,
                                    open: true
                                }
                            });
                        } else {
                            goBook(selectBookID);
                        }
                    }
                }
            });
        }, 1000);

    };

    //備課與活動選單
    const TabDialogContent = <AnnotationActivityTab id="alert-dialog-description" setLoadingOpen={setLoadingOpen} setAnnotationTabOpen={setAnnotationTabOpen} tab={tab} setTab={setTab} TabAnnotationType={role === Roles.TEACHER ? TabAnnotationType : [AnnotationType.GUEST]} setInputText={setInputText} />;
    const TabDialogAction = <div>
        <Button onClick={() => {
            setInputDialogOpen(true)
        }} color="primary"
            style={{
                border: "solid",
                color: "blue",
                marginRight: 5,
                borderWidth: 2
            }}
        >
            新增
        </Button>
        <Button
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
            onClick={confirmHandler(annotationId)} color="primary" autoFocus
        >
            確定
        </Button>
    </div>;

    //輸入名稱彈窗
    const InputDialogContent = <TextField
        id="outlined-name"
        label="輸入名稱"
        value={inputText}
        onChange={(e) => {
            setInputText(e.target.value)
        }}
        margin="normal"
        variant="outlined"
    />
    const InputDialogAction = (
        <Button
            className={styles.dialogButton}
            onClick={confirmHandler(null)}
            color="primary"
            autoFocus
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
        >
            確定
        </Button>
    )

    const ProgressContent = <div className={styles.progressContent}>
        <div className={styles.text}>{progressText}</div>
        <div className={styles.progress}><LinearProgress variant="determinate" value={progress || 0} /></div>
    </div>

    const LoadingContent = <div className={styles.loadingContent}>
        <div className={styles.text}>{loadingText}</div>
        <div className={styles.loading}><CircularProgress disableShrink /></div>
    </div>

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    function handleClose() {
        setState({ ...state, open: false });
    }

    const changePage = (event, page) => {
        (async () => {
            const tagData = [...SchoolYear, ...EducationalSystem, ...Subject, ...Grade];
            setFilterTag(tagData);
            await getBookList(page, tagData);
            scrollTo(root, 0, 300);
            setPageState({ currentPage: page })
        })();
    }

    const pageCount = useMemo(() => {
        if (bookList.total > 0) {
            return Math.ceil(bookList.total / 10)
        }
        return 0
    }, [bookList.total])

    const filterChange = (event, value, key) => {
        let tagArr = [];
        const optionObj = value;
        const category = key;

        if (optionObj === null) {
            const deleteOrderTagIndex = filterOrderTag.findIndex(item => {
                return item.category === category
            });

            if (deleteOrderTagIndex === -1) {
                setTag({ [category]: tagArr });
                return
            };

            const updateOrderArr = [...filterOrderTag];

            updateOrderArr.splice(deleteOrderTagIndex, 1);
            setFilterOrderTag([...updateOrderArr]);

        } else {
            const isSkip = (optionObj.category !== 'SchoolYear' && optionObj.label !== '低年級' && optionObj.label !== '中年級' && optionObj.label !== '高年級');
            tagArr.push(optionObj);

            if (filterOrderTag.length > 0 && isSkip) {
                const someIndex = filterOrderTag.findIndex(item => optionObj.category === item.category);
                if (someIndex !== -1) {
                    const updateOrderArr = [...filterOrderTag];
                    updateOrderArr[someIndex] = optionObj
                    setFilterOrderTag([...updateOrderArr]);
                } else {
                    setFilterOrderTag([...filterOrderTag, ...tagArr]);
                }
            } else if (isSkip) {
                setFilterOrderTag([...filterOrderTag, ...tagArr]);
            }
        }
        setTag({ [category]: tagArr });
        setPageState({ currentPage: 1 });
    };

    // 篩選 options
    const filterCategoryOptions = (tableType, options) => {
        const baseType = ['EducationalSystem', 'Grade', 'Subject'];
        let tagObj = {};
        let levelTwoCategory = '';
        let levelTwoOption = '';
        let levelThreeCategory = '';
        let table = {};
        const isMultiple = options.length > 1;
        const levelOneOption = options.length > 0 ? options[0].label : '';

        if (isMultiple) {
            levelTwoCategory = options[1].category;
            levelTwoOption = options[1].label;
            levelThreeCategory = baseType.filter(item => { return item !== tableType && item !== levelTwoCategory });
        }

        switch (tableType) {
            case 'EducationalSystem':
                tagObj['Grade'] = getOptionsData('Grade', Object.keys(educationalSystemTable[levelOneOption].Grade));
                tagObj['Subject'] = getOptionsData('Subject', Object.keys(educationalSystemTable[levelOneOption].Subject));
                table = educationalSystemTable;
                break;
            case 'Grade':

                tagObj['EducationalSystem'] = getOptionsData('EducationalSystem', Object.keys(gradeTable[levelOneOption].EducationalSystem))
                tagObj['Subject'] = getOptionsData('Subject', Object.keys(gradeTable[levelOneOption].Subject))

                table = gradeTable;
                break;
            case 'Subject':

                tagObj['EducationalSystem'] = getOptionsData('EducationalSystem', Object.keys(subjectTable[levelOneOption].EducationalSystem))
                tagObj['Grade'] = getOptionsData('Grade', Object.keys(subjectTable[levelOneOption].Grade))

                table = subjectTable;
                break;
            default:
                tagObj = { ...initTag };
                break;
        }

        if (isMultiple) {
            tagObj[levelTwoCategory] = getOptionsData(levelTwoCategory, Object.keys(table[levelOneOption][levelTwoCategory]));
            tagObj[levelThreeCategory] = getOptionsData(levelThreeCategory, table[levelOneOption][levelTwoCategory][levelTwoOption]);
        }
        setBookTags({ 'SchoolYear': initTag.SchoolYear, ...tagObj });
    };

    // 取得 option 資訊
    const getOptionsData = (key, optionArr) => {
        const newOptions = initTag[key].filter(item => {
            return optionArr.find(option => {
                return option === item.label
            });
        });
        return newOptions;
    };

    const addShelf = (event, bookData) => {
        event.stopPropagation();
        if (!token) {
            alertShelfDialog('請先登入');
            return
        }

        const isBookIdExist = books.hasOwnProperty(bookData.productItemId);

        if (isBookIdExist) {
            alertShelfDialog('已經在書櫃裡囉！');
            return
        }

        const currentTime = Date.now();
        const shelfBookObj = {};

        shelfBookObj[bookData.productItemId] = {
            collectedTime: currentTime,
            bookId: bookData.bookId,
            id: bookData.productItemId
        };

        updateUserFavorites({ books: { ...books, ...shelfBookObj } });
        alertShelfDialog('您已成功將書本放入書櫃！');
    }

    // 加入書櫃提示
    const alertShelfDialog = (content) => {
        setShelfDialog({ state: true, content: content });
        setTimeout(() => {
            setShelfDialog({ state: false, content: content });
        }, 800)
    }

    useEffect(() => {
        const isFilter = filterOrderTag.length > 0;

        if (!isFilter) {
            filterCategoryOptions('init', filterOrderTag);
        } else {
            let tableCategory = filterOrderTag[0].category;
            filterCategoryOptions(tableCategory, filterOrderTag);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOrderTag]);

    useEffect(() => {
        const tagData = [...SchoolYear, ...EducationalSystem, ...Subject, ...Grade];
        setFilterTag(tagData);
        getBookList(1, tagData);
        scrollTo(root, 0, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SchoolYear, EducationalSystem, Subject, Grade]);

    return (
        <>
            <Header path={'線上書城'} />
            <LoginPopup />
            <div className={styles.index}>
                <div className={styles.container}>
                    <div className={styles.filter}>
                        <Autocomplete title={'學年度'} optionsData={bookTags.SchoolYear} onChange={(event, value) => filterChange(event, value, 'SchoolYear')} />
                        <Autocomplete title={'學制'} optionsData={bookTags.EducationalSystem} onChange={(event, value) => filterChange(event, value, 'EducationalSystem')} />
                        <Autocomplete title={'年級'} optionsData={bookTags.Grade} onChange={(event, value) => filterChange(event, value, 'Grade')} />
                        <Autocomplete title={'科目'} optionsData={bookTags.Subject} onChange={(event, value) => filterChange(event, value, 'Subject')} />
                    </div>

                    {
                        isLoading ? (
                            <Loading />
                        ) : (
                            <>
                                {bookList.lists.length > 0 ?
                                    <ul className={styles['list-container']}>
                                        {bookList.lists.map(book =>
                                            <Card book={book} BookContentRepository={BookContentRepository} addShelf={addShelf} key={book.bookId} />
                                        )}
                                    </ul> : null}
                                {bookList.lists.length === 0 && filterTag.length !== 0 ? <div className={styles.noResult}>找不到符合搜尋條件的書本</div> : null}
                                <DialogModule
                                    open={addShelfDialog.state}
                                    content={addShelfDialog.content}
                                    style={{ paddingBottom: '20px' }}
                                />
                                {bookList.total > 0 ? <Pagination count={pageCount} page={pageState.currentPage} onChange={changePage}></Pagination> : null}
                            </>
                        )
                    }
                    

                    {
                        (role === Roles.TEACHER || role === Roles.GUEST || role === Roles.STUDENT || role === Roles.PARENT) && (<div>
                            <DialogModule
                                open={isAnnotationTabOpen}
                                setOpen={setAnnotationTabOpen}
                                content={TabDialogContent}
                                action={TabDialogAction}
                            />
                            <DialogModule
                                open={inputDialogOpen}
                                setOpen={setInputDialogOpen}
                                content={InputDialogContent}
                                action={InputDialogAction}
                            />
                            <DialogModule
                                open={progressOpen}
                                content={ProgressContent}
                            />
                            <DialogModule
                                open={loadingOpen}
                                content={LoadingContent}
                            />
                        </div>)
                    }

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        key={`${vertical},${horizontal}`}
                        open={open}
                        onClose={handleClose}
                    >
                        <Button variant="contained" style={{ backgroundColor: "#f50057", color: "#ccc", width: 200, height: 70, fontSize: 32 }} >
                            請登入
                    </Button>
                    </Snackbar>
                </div>
            </div>
            <Footer />
        </>
    );
};
