import React from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderToolType, SVGCanvasSwitchType } from 'constants/ReaderTools';
import { useStore, StoreTypes } from 'context';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';

import { withTranslation } from 'react-i18next';

const ReaderToolBookshelf = ({ t, type }) => {
    const router = useStore(StoreTypes.router);
    const clickHandler = () => {
        EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolTypeEvent,
            payload: {
                readerToolType: ReaderToolType.Drag
            }
        });

        EventBus.emit({
            event: ReaderToolsEvent.ClickDragEvent
        });

        router.history.push('/bookshelf');
    }
    return (
        <Button key={type} onClick={clickHandler}>
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ReaderToolBookshelf);
