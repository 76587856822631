import { useRef, useEffect, useCallback, useState, useMemo } from "react";
import { useStore, StoreTypes } from "context";
import {
  InteractiveObjectEvent,
  ReaderToolsEvent,
  ReaderEvent,
  CanvasEvent,
} from "events/EventTypes";
import { EventBus, EventBusType } from "events/EventBus";
import {
  useUpdateAnnotation,
  useReadAnnotations,
  useCreateAnnotation,
} from "customHooks/db";
import { useRefreshReader } from "customHooks/reader";
import { Roles } from "constants/role";
import { useSetPageIndex } from "customHooks/reader";
import * as types from "constants/actionTypes";
import { ReaderToolType, ReaderZoomType } from "constants/ReaderTools";
import useSetState from "customHooks/setState";
import { debounce } from "util/debounce";
import domtoimage from "dom-to-image";
import { b64toBlob } from "util/blob";
import { API } from "api";
import { mergeCanvasJSON } from "util/svg";
import useModal from "components/common/Modal/useModal";
import { BOOK_ORIENTATION } from "constants/books";
import { useEvent } from "events/EventBus";

let fristCheck = false;
let studentFristOnSnapshot;
let doSnapshotEvent = false;
let chatSnapshot;
let canvasListSnapshot;
let courseSnapshot;
let targetId;

export const useCourse = () => {
  const updateAnnotation = useUpdateAnnotation();
  const { readAnnotationById } = useReadAnnotations();
  const { refreshReader } = useRefreshReader();
  const { setPageIndex } = useSetPageIndex();

  const [, globalDispatch] = useStore(StoreTypes.global);
  const [{ onConnected }] = useStore(StoreTypes.course);
  const [{ orientation }] = useStore(StoreTypes.books);
  const [{ isSynchronousCamera, pageIndex, isDoublePageMode }, readerDispatch] =
    useStore(StoreTypes.reader);
  const [{ canvasJSON, saveCanvasTime }] = useStore(StoreTypes.canvas);
  const [
    {
      courseId: roomId,
      timeSpanId,
      firestore,
      startAt,
      endAt,
      organizationId,
      rewardInfo,
      finishedBefore,
    },
    courseDispatch,
  ] = useStore(StoreTypes.course);
  const [{ userId, name: userName, role: userRole, token }] = useStore(
    StoreTypes.user
  );

  const [annotationId, setAnnotationId] = useState(roomId);
  const isCanvasSnapshotInit = useRef(false);
  const eventStatusRef = useRef({});
  const infoStatusRef = useRef({});

  const [
    { data, users, syncCanvasTargetId, isSyncingCanvas, canvasQueue },
    setState,
  ] = useSetState({
    data: [],
    users: [],
    syncCanvasTargetId: "",
    isSyncingCanvas: false,
    canvasQueue: [],
  });

  const getUserId = useCallback(() => {
    if (!userId) return null;
    const id = userId.split("$")[0];
    return id;
  }, [userId]);

  const userEventStatusRef = useCallback(
    (targetId) => {
      const id = targetId ? targetId : getUserId();
      if (!firestore || !roomId || !id) return;
      return firestore
        .firestore()
        .collection(`course/${roomId}/users/${id}/eventStatus`);
    },
    [firestore, roomId, getUserId]
  );

  const chatCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/chat`);
  }, [firestore, roomId]);

  const canvasListCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/canvas`);
  }, [firestore, roomId]);

  const annotaionsCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/annotations`);
  }, [firestore, roomId]);

  const usersCollRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.firestore().collection(`course/${roomId}/users`);
  }, [firestore, roomId]);

  const canvasCollRef = useCallback(
    (targetId) => {
      if (!firestore || !roomId) return;
      const id = targetId ? targetId : getUserId();
      return firestore
        .firestore()
        .collection(`course/${roomId}/users/${id}/canvas`);
    },
    [firestore, roomId, getUserId]
  );

  const courseCollRef = useCallback(
    (targetId) => {
      if (!firestore) return;
      return firestore.firestore().collection(`course`);
    },
    [firestore]
  );

  const refreshReaderHandler = useCallback(debounce(refreshReader, 1), [
    refreshReader,
  ]);

  const syncAnnotationSnapshotToDB = useCallback(
    async ({ annotations, userId: id }) => {
      let annotationSnapshotMap = [];
      JSON.parse(annotations).forEach((item) => {
        annotationSnapshotMap.push(item);
      });

      const result = await readAnnotationById({ id: roomId });
      const newAnnotations = result.annotations.length > 0 ? result.annotations.map((info) => {
        const cloudAnnotation = annotationSnapshotMap.find((newInfo) => newInfo.pageIndex === info.pageIndex)
        if (cloudAnnotation) {
          return cloudAnnotation
        } else {
          return info
        }
      }) : annotationSnapshotMap;

      await updateAnnotation(roomId, {
        annotations: newAnnotations,
        isDirty: true,
        updatedAt: Date.now(),
        lastSyncedAt: Date.now(),
        isDoublePageMode:
          orientation === BOOK_ORIENTATION.LANDSCAPE ? false : true,
      });

      id !== userId && refreshReaderHandler();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orientation, refreshReaderHandler, roomId, updateAnnotation, userId]);

  const setEventStatusToFirebase = useCallback(
    (payload) => {
      if (!roomId || !userEventStatusRef()) return;
      if (userRole === Roles.TUTOR_USER) return;
      const date = new Date();
      const ISOString = date.toISOString();
      const timestamp = date.getTime();
      Object.entries(payload).forEach(([key, value]) => {
        eventStatusRef.current = {
          ...eventStatusRef.current,
          ...payload,
        };
        userEventStatusRef()
          .doc(ISOString)
          .set({ value: eventStatusRef.current, timestamp });
      });
    },
    [roomId, userEventStatusRef, userRole]
  );

  const setSyncCanvasTargetId = useCallback(
    (payload) => {
      if (!roomId && userRole !== Roles.TEACHER_REMOTE) return;
      Object.entries(payload).forEach(([key, value]) => {
        infoStatusRef.current = {
          ...infoStatusRef.current,
          ...payload,
        };
      });

      courseCollRef().doc(`${roomId}`).set(payload, { merge: true });
      setState(payload);
    },
    [courseCollRef, roomId, setState, userRole]
  );

  const storgeRef = useCallback(() => {
    if (!firestore || !roomId) return;
    return firestore.storage().ref();
  }, [firestore, roomId]);

  const resetReaderState = useCallback(
    (teacherId) => {
      userEventStatusRef(teacherId)
        .orderBy("timestamp", "desc")
        .limit(1)
        .get()
        .then(function (snapshot) {
          snapshot.forEach((doc) => {
            const data = doc.data().value;
            Object.entries(data).forEach(([key, value]) => {
              switch (key) {
                case "pageIndex":
                  setPageIndex({ pageIndex: value });
                  pageIndexRef.current = pageIndex;
                  break;
                case "isDoublePageMode":
                  EventBus.emit({
                    event: ReaderToolsEvent.TogglePageModeEvent,
                    payload: { isDoublePageMode: value },
                  });
                  break;
                case "areaInfo":
                  const { type, rect, zoomInfo } = value;
                  if (
                    type === ReaderZoomType.WheelZoom ||
                    type === ReaderZoomType.RangeZoom
                  ) {
                    EventBus.emit({
                      event: ReaderEvent.SetAreaZoomIntoEvent,
                      payload: { type, rect, zoomInfo },
                    });
                  } else if (type === ReaderZoomType.PanZoom) {
                    courseDispatch({
                      type: types.SET_REMOTE_ZOOM_INFO,
                      remoteZoomInfo: zoomInfo,
                    });
                    EventBus.emit({
                      event: ReaderToolsEvent.ZoomToolEvent,
                      payload: { type },
                    });
                  } else {
                    EventBus.emit({
                      event: ReaderToolsEvent.ZoomToolEvent,
                      payload: { type },
                    });
                    readerDispatch({
                      type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS,
                      areaZoomInteractiveObjects: null,
                    });
                  }
                  break;
                case "interactiveObjectEvent":
                  EventBus.emit({
                    event:
                      InteractiveObjectEvent.SetInteractiveObjectStateEvent,
                    payload: {
                      json: { ...value.json, timestamp: new Date().getTime() },
                    },
                  });
                  break;
                case "canvasSVGObjectEvent":
                  EventBus.emit({
                    event: ReaderEvent.ExecuteCanvasSVGCommandEvent,
                    payload: { ...value.json, timestamp: new Date().getTime() },
                  });
                  break;
                case "canvasSVGObjectSaveAnnotationEvent":
                  const { annotations } = value.json;
                  EventBus.emit({
                    eventBusType: EventBusType.ExtendedContent,
                    event: CanvasEvent.CanvasImportJSONEvent,
                    payload: { annotations, timestamp: new Date().getTime() },
                  });
                  break;
                case "emojiEvent":
                  EventBus.emit({
                    event: ReaderToolsEvent.SetRewardInfoEvent,
                    payload: {
                      rewardInfo: value.emoji,
                      timestamp: new Date().getTime(),
                    },
                  });
                  break;
                case "expressionEvent":
                  EventBus.emit({
                    event: ReaderToolsEvent.PlayExpression,
                    payload: { expressionType: value.expressionType },
                  });
                  break;
                case "closePannelEvent":
                  globalDispatch({
                    type: types.CLOSE_MODAL,
                  });
                  globalDispatch({
                    type: types.CLOSE_MUSIC_MODAL,
                  });
                  break;
                default:
                  break;
              }
            });
          });
        });
    },
    [
      userEventStatusRef,
      setPageIndex,
      pageIndex,
      globalDispatch,
      courseDispatch,
      readerDispatch,
    ]
  );

  useEffect(() => {
    if (!firestore || !roomId || !userRole || userRole === Roles.GUEST) return;
    // isCanvasSnapshotInit.current = true;

    delEventStatus(userId);
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderZoomEvent,
      payload: { type: ReaderZoomType.OriginZoom },
    });

    if (courseSnapshot) {
      courseSnapshot();
      courseSnapshot = null;
    }

    courseSnapshot = courseCollRef()
      .doc(`${roomId}`)
      .onSnapshot((snapshot) => {
        if (snapshot.data() === undefined) return;
        doSnapshotEvent = false;
        EventBus.emit({
          event: ReaderToolsEvent.ClickDragEvent,
        });
        EventBus.emit({
          event: ReaderToolsEvent.SetReaderToolTypeEvent,
          payload: {
            readerToolType: ReaderToolType.Drag,
          },
        });
        targetId = snapshot.data().syncCanvasTargetId;
        setState({ syncCanvasTargetId: targetId });
        if (snapshot.data().isSynchronousCamera !== undefined) {
          EventBus.emit({
            event: ReaderToolsEvent.SetSyncCameraEvent,
            payload: { isOpen: snapshot.data().isSynchronousCamera },
          });
        }
      });

    if (chatSnapshot) {
      chatSnapshot();
      chatSnapshot = null;
    }

    chatSnapshot = chatCollRef().onSnapshot((snapshot) => {
      let data = [];
      snapshot.docs.map((item) => {
        data.push(item.data());
      });
      setState({ data });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCollRef, roomId, firestore, userRole]);

  useEffect(() => {
    if (!canvasListCollRef() || !userId || !onConnected) return;
    if (!canvasListSnapshot) {
      canvasListSnapshot = canvasListCollRef().onSnapshot((snapshot) => {

        let canvasJSONs = [];
        snapshot.docs.map((item) => {
          canvasJSONs.push(item.data());
        });
        const lastRecode =
          canvasJSONs.length > 0 ? canvasJSONs[canvasJSONs.length - 1] : null;

        if (!lastRecode || !lastRecode.annotations) {
          return;
        }

        syncAnnotationSnapshotToDB(lastRecode);
      });
    }
    return () => {
      if (canvasListSnapshot) {
        canvasListSnapshot()
        canvasListSnapshot = null
      }
    }
  }, [canvasListCollRef, syncAnnotationSnapshotToDB, userId, onConnected]);

  const pageIndexRef = useRef(pageIndex);
  useEffect(() => {
    if (!courseCollRef()) return;
    const isTeacher =
      userRole === Roles.TUTOR || userRole === Roles.TEACHER_REMOTE;
    if (!firestore || !isTeacher || !roomId) return;
    courseCollRef().doc(`${roomId}`).set({
      syncCanvasTargetId: userId,
    });
  }, [firestore, roomId, userId, userRole, courseCollRef]);

  // ONECLASS_STUDENT 接收事件狀態更新
  useEffect(() => {
    if (studentFristOnSnapshot) {
      studentFristOnSnapshot();
    }

    if (!firestore || !roomId || !syncCanvasTargetId || !userId) return;
    if (syncCanvasTargetId === userId) return;
    studentFristOnSnapshot = userEventStatusRef(syncCanvasTargetId)
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data().value;
          Object.entries(data).forEach(([key, value]) => {
            switch (key) {
              case "pageIndex":
                if (value !== null) {
                  canvasListCollRef()
                    .get()
                    .then(function (snapshot) {
                      snapshot.forEach((change) => {
                        change.ref.delete();
                      });
                    });
                  annotaionsCollRef()
                    .get()
                    .then(function (snapshot) {
                      snapshot.forEach((change) => {
                        change.ref.delete();
                      });
                    });
                  setPageIndex({ pageIndex: value });
                  pageIndexRef.current = pageIndex;
                }

                break;
              case "isDoublePageMode":
                EventBus.emit({
                  event: ReaderToolsEvent.TogglePageModeEvent,
                  payload: { isDoublePageMode: value },
                });
                break;
              case "areaInfo":
                const { type, rect, zoomInfo } = value;
                if (
                  type === ReaderZoomType.WheelZoom ||
                  type === ReaderZoomType.RangeZoom
                ) {
                  EventBus.emit({
                    event: ReaderEvent.SetAreaZoomIntoEvent,
                    payload: { type, rect, zoomInfo },
                  });
                } else if (type === ReaderZoomType.PanZoom) {
                  courseDispatch({
                    type: types.SET_REMOTE_ZOOM_INFO,
                    remoteZoomInfo: zoomInfo,
                  });
                  EventBus.emit({
                    event: ReaderToolsEvent.ZoomToolEvent,
                    payload: { type },
                  });
                  readerDispatch({
                    type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS,
                    areaZoomInteractiveObjects: null,
                  });
                } else {
                  EventBus.emit({
                    event: ReaderToolsEvent.ZoomToolEvent,
                    payload: { type },
                  });
                }
                break;
              case "interactiveObjectEvent":
                if (value.json) {
                  EventBus.emit({
                    event:
                      InteractiveObjectEvent.SetInteractiveObjectStateEvent,
                    payload: {
                      json: { ...value.json, timestamp: new Date().getTime() },
                    },
                  });
                } else {
                  readerDispatch({
                    type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS,
                    areaZoomInfos: null,
                  });
                  globalDispatch({
                    type: types.CLOSE_MODAL,
                  });
                  globalDispatch({
                    type: types.CLOSE_MUSIC_MODAL,
                  });
                }
                break;
              case "stampCollection":
                const { isChecked, id } = value;
                EventBus.emit({
                  event: InteractiveObjectEvent.SetStampCollection,
                  payload: { isChecked, id },
                });
                break;
              case "canvasSVGObjectEvent":
                if (!doSnapshotEvent) return;
                EventBus.emit({
                  event: ReaderEvent.ExecuteCanvasSVGCommandEvent,
                  payload: { ...value.json, timestamp: new Date().getTime() },
                });
                break;
              case "canvasSVGObjectSaveAnnotationEvent":
                const { annotations } = value.json;
                EventBus.emit({
                  eventBusType: EventBusType.ExtendedContent,
                  event: CanvasEvent.CanvasImportJSONEvent,
                  payload: { annotations, timestamp: new Date().getTime() },
                });
                break;
              case "emojiEvent":
                EventBus.emit({
                  event: ReaderToolsEvent.SetRewardInfoEvent,
                  payload: {
                    rewardInfo: value.emoji,
                    timestamp: new Date().getTime(),
                  },
                });
                break;
              case "expressionEvent":
                if (!doSnapshotEvent) return;
                EventBus.emit({
                  event: ReaderToolsEvent.PlayExpression,
                  payload: { expressionType: value.expressionType },
                });
                break;
              case "fullVideoEvent":
                EventBus.emit({
                  event: ReaderEvent.setFullVideoEvent,
                  payload: { isFullVideo: value.isFullVideo },
                });
                break;
              case "openSyncCamera":
                EventBus.emit({
                  event: ReaderToolsEvent.SetSyncCameraEvent,
                  payload: { isOpen: value.json },
                });
                break;
              case "closePannelEvent":
                globalDispatch({
                  type: types.CLOSE_MODAL,
                });
                globalDispatch({
                  type: types.CLOSE_MUSIC_MODAL,
                });
                break;
              case "resetReaderEvent":
                const { userId } = value;
                resetReaderState(userId);
                break;
              default:
                break;
            }
          });
        });

        doSnapshotEvent = true;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, firestore, roomId, userRole, syncCanvasTargetId]);

  const delEventStatus = useCallback(
    (userId) => {
      if (!firestore) return;
      const getUserStatus = firestore
        .firestore()
        .collection(`course/${roomId}/users/${userId}_remote/eventStatus`);

      getUserStatus &&
        getUserStatus.get().then(function (snapshot) {
          snapshot.forEach((change) => {
            change.ref.delete();
          });
        });
    },
    [firestore, roomId]
  );

  const sendMessage = (message) => {
    if (!message) return;
    const date = new Date();
    chatCollRef()
      .doc(date.toISOString())
      .set({ userId, userName, userRole, message, timestamp: date.getTime() });
  };

  const sendAnnotations = useCallback(
    ({ annotation }) => {
      if (!canvasListCollRef() || !onConnected) return;
      const date = new Date();
      canvasListCollRef()
        .doc(date.toISOString())
        .set({
          annotations: JSON.stringify(annotation.annotations),
          timestamp: date.getTime(),
          userId,
        });
    },
    [canvasListCollRef, userId, onConnected]
  );

  useEvent({ event: CanvasEvent.CanvasSavedEvent }, sendAnnotations);
  return [
    { data, users },
    {
      sendMessage,
      setEventStatusToFirebase,
      setSyncCanvasTargetId,
      resetReaderState,
      delEventStatus,
    },
  ];
};
