import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import Draggable from 'react-draggable';

const BoxModal = ({ children, onCloseButtonClick }) => {
  
  return (
    <Draggable 
      handle={`.${styles.controlHead}`}
      positionOffset={{x: '-50%', y: '-50%'}}
    >
      <div className={styles.boxModal}>
        <div className={styles.content}>
          {children}
          <div className={styles.bottom}>
            <div className={styles.controlHead}>
              <div className={styles.closeButton} onClick={onCloseButtonClick}>
                <span>關閉</span>
                <Icon type="text" name="times" />
              </div>
            </div>
            <div className={styles.expandIcon}>
                <Icon type="text" name="expandAlt" />
              </div>
          </div>
        </div>
      </div>
    </Draggable>
  )
}


export default BoxModal;