import url from 'url';

export const PreparationAPI = {
    post:(url,params,jwt)=>{
        const body = JSON.stringify(params);
        return fetch(url,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                'Authorization':jwt
            },
            body
        })
        .then(response=>response.json())
        .catch(err=>console.error(err));
    },
    get: (address, params, jwt) => {
        const parsed = url.parse(address);
        return fetch(url.format({
            ...parsed,
            query: params
        }), {
            method: 'GET',
            headers: {
                'Authorization': jwt
            }
        })
        .then(response => response.json())
        .catch(err => console.error(err));
    },
    put: (url, params, jwt) => {
        const body = JSON.stringify(params);
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body
        })
        .then(response => response.json())
        .catch(err => console.error(err));
    },
    getById: (url, jwt) => {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': jwt
            }
        })
        .then(response => response.json())
        .catch(err => console.error(err));
    },
}

