import React, { useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

const Placeholder = props => {
    const router = useStore(StoreTypes.router);
    const [, bookDispatch] = useStore(StoreTypes.books);
    let urlParams = new URLSearchParams(props.location.search);
    let bookId = urlParams.get('bookId');

    useEffect(() => {
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        router.history.replace({ pathname: `/${bookId}` });
    }, [bookDispatch, bookId, router.history]);

    return (
        <div>
            Now loading...
        </div>
    )
};

export default Placeholder;
