import React, { useEffect } from 'react';
import styles from './index.module.scss';
import useSetState from 'customHooks/setState';
import Repository from 'repositories/Repository';
import { useStore, StoreTypes } from 'context';
import './content.scss'
const { ExtendedResourceRepository } = Repository;

const WordRadicalContent = ({ htmlPath, imgPath }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ html }, setState] = useSetState({ html: '' });

  useEffect(() => {
    const getHtml = async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: htmlPath ? htmlPath.split('.json')[0] : ""
      })
      setState({ html: htmlJson.html })
    }
    htmlPath && getHtml();
  }, [bookId, htmlPath, setState]);

  return (
    <div className={styles.wordRadicalContent}>
      {
        imgPath && <img src={ExtendedResourceRepository.getDataSrc({
          bookId,
          pathName: imgPath
        })}
          alt=""
        />
      }
      {
        html && <div className={styles.content} dangerouslySetInnerHTML={{ __html: html }}></div>
      }
    </div>
  )
}

export default WordRadicalContent;
