import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { preserveSVGAspectRatio } from 'util/book';

const BookPage = ({ content, bookInfo, pageIndex }) => {
  const [{ isDoublePageMode, isMarkModeShow }] = useStore(StoreTypes.reader);

  return (
    <div className={styles.svgWrapper}>
      <div
        className={classnames(styles.svgContent, {
          [styles.disableSelection]: !isMarkModeShow
        })}
        dangerouslySetInnerHTML={{
          __html: preserveSVGAspectRatio({
            svg: content,
            ...bookInfo,
            pageIndex,
            isDoublePageMode
          })
        }}
      />
    </div>
  );
};

export default BookPage;
