import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

export const ContentTypeDisplayMap = {
  [InteractiveObjectContentType.ExternalWindow]: '外開連結',
  [InteractiveObjectContentType.InternalWindow]: '內開視窗',
  [InteractiveObjectContentType.ToggleDisplay]: '蓋答案',
  [InteractiveObjectContentType.ControlDisplay]: '全解',
  [InteractiveObjectContentType.Stage]: '步驟編號',
  [InteractiveObjectContentType.ControlStage]: '步驟解',
  [InteractiveObjectContentType.Image]: '外開連結',
  [InteractiveObjectContentType.Image]: '圖片',
  [InteractiveObjectContentType.ControlImage]: '換圖',
  [InteractiveObjectContentType.GoPage]: '跳頁',
  [InteractiveObjectContentType.Video]: '影片',
  [InteractiveObjectContentType.Audio]: '音檔',
  [InteractiveObjectContentType.AreaZoom]: '區域放大',
  [InteractiveObjectContentType.InternalHtmlWindow]: 'HTML內開視窗',
  [InteractiveObjectContentType.Vocabulary]: '單字例句',
  [InteractiveObjectContentType.Translation]: '翻譯小視窗',
  [InteractiveObjectContentType.WordGrid]: '生字庫',
  [InteractiveObjectContentType.VolumeOneWords]: '首冊生字庫',
  [InteractiveObjectContentType.Submenu]: '次選單',
  [InteractiveObjectContentType.WordExample]: '語詞選單',
  [InteractiveObjectContentType.Synopsis]: '多題小視窗',
  [InteractiveObjectContentType.Hearing]: '聽力稿',
  [InteractiveObjectContentType.FlashCard]: 'Flash Card',
  [InteractiveObjectContentType.Listening]: '聆聽故事',
  [InteractiveObjectContentType.WordGlyph]: '字音字形',
  [InteractiveObjectContentType.WordMeaning]: '字義教學',
  [InteractiveObjectContentType.AboutTheAuthor]: '作者介紹',
  [InteractiveObjectContentType.ReadAloud]: '朗讀',
  [InteractiveObjectContentType.ZhuyinLesson]: '注音課文',
  [InteractiveObjectContentType.ZhuyinImage]: '注音圖片',
  [InteractiveObjectContentType.ZhuyinWord]: '一音二詞',
  [InteractiveObjectContentType.MathTools]: '尺規作圖',
  [InteractiveObjectContentType.MeasureTools]: '測量工具',
};