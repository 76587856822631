import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { ReaderZoomType } from 'constants/ReaderTools';
import * as types from 'constants/actionTypes';
import { SideToolContent } from 'constants/sideToolContents';
import { getDeviceXY } from 'util/device'

const DrawArea = ({ drawAreaInfo }) => {
    const [{ style, bookContent }] = useStore(StoreTypes.books);
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const [{ sideToolContent }] = useStore(StoreTypes.sideTool);
    const [isMove, setIsMove] = useState(0)
    const [rect, setRect] = useState({ x: 0, y: 0, width: 0, heght: 0 })
    const [livestreamElementInfo, setLivestreamElementInfo] = useState({ height:0 })
    const rectRef = useRef();
    const [bookRangeInfo, setBookRangeInfo] = useState(null)

    useEffect(() => {
        const pageContentWidth = bookContent[0].width * 2
        const pageContentHeight = bookContent[0].height
        const scale = (style.width / pageContentWidth)
        const pageWidth = pageContentWidth * scale
        const pageHeight = pageContentHeight * scale
        const offsetX = ((style.width - pageWidth) / 2)
        const offsetY = ((style.height - pageHeight) / 2)

        setBookRangeInfo({ offsetX, offsetY, width: pageWidth, height: pageHeight })
    }, [style, bookContent])

    const mouseDownHandler = (e) => {
        const { deviceX, deviceY } = getDeviceXY(e)
        //console.log(deviceX,deviceY)
        setIsMove(1)
        if (deviceX - bookRangeInfo.offsetX < 0 || deviceY - bookRangeInfo.offsetY < 0) {
            EventBus.emit({ event: ReaderEvent.DrawAreaEvent, payload: { drawAreaInfo: { isDrawArea: false, callback: null, step: null } } });
            return;
        }
        const { width, height } = rect;
        setRect({ x: deviceX, y: deviceY, width, height })
        rectRef.current.style.top = `${(deviceY)}px`
        rectRef.current.style.left = `${(deviceX)}px`
    }

    const mouseMoveHandler = (e) => {
        if (isMove === 0) {
            return;
        }
        const { deviceX, deviceY } = getDeviceXY(e)
        const { x, y } = rect;
        rectRef.current.style.position = "absolute"
        rectRef.current.style.backgroundColor = "aqua"
        rectRef.current.style.width = `${(deviceX - x)}px`
        rectRef.current.style.height = `${(deviceY - y)}px`
        //console.log(x,y,(deviceX - x),(deviceY - y))
        setRect({ x, y, width: (deviceX - x), height: (deviceY - y) })
        setIsMove(2)
    }

    const mouseUpHandler = (e) => {
        EventBus.emit({ event: ReaderEvent.DrawAreaEvent, payload: { drawAreaInfo: { isDrawArea: false, callback: null, step: null } } });
        if (isMove === 2) {
            let { x, y, width, height } = rect;
            const pageContentWidth = bookContent[0].width * 2
            const pageContentHeight = bookContent[0].height
            const scaleWidth = (style.width / pageContentWidth)
            const scale = (style.height / pageContentHeight)
            const originScale = (pageContentHeight / style.height)
            const offsetX = ((style.width - pageContentWidth * scaleWidth) / 2)
            const offsetY = ((style.height - pageContentHeight * scaleWidth) / 2)
            const nowPageWidth = pageContentWidth * scaleWidth
            const nowPageHeight = style.height//pageContentHeight * scaleWidth
            const areaZoomInfo = {
                originScale,
                originX: x,
                originY: y,
                originWidth: width,
                originHeight: height,
                originXScale: x / nowPageWidth,
                originYScale: y / nowPageHeight,
                originXScale2: (x-bookRangeInfo.offsetX) / nowPageWidth,
                originYScale2: (y-bookRangeInfo.offsetY) / nowPageHeight,
                originWidthScale: width / nowPageWidth,
                originHeightScale: height / nowPageHeight,
                offsetX,
                offsetY,
                x: (x - offsetX) * originScale,
                y: (y - offsetY) * originScale,
                width: (width * originScale),
                height: ((height) * originScale)
            }

            if (drawAreaInfo.step != null) {
                drawAreaInfo.callback({ areaZoomInfo: { ...areaZoomInfo, step: drawAreaInfo.step } })
            }
            sideToolContent === SideToolContent.None && EventBus.emit({ event: ReaderEvent.AreaZoomEvent, payload: { type: ReaderZoomType.RangeZoom, rect: areaZoomInfo } })
        }
        setIsMove(0)
    }

    return (
        <div
            id="drawArea"
            className={styles.drawArea}
            onMouseDown={(e) => { mouseDownHandler(e) }}
            onMouseMove={(e) => { mouseMoveHandler(e) }}
            onMouseUp={(e) => { mouseUpHandler(e) }}
            onTouchStart={(e) => { mouseDownHandler(e) }}
            onTouchMove={(e) => { mouseMoveHandler(e) }}
            onTouchEnd={(e) => { mouseUpHandler(e) }}
        >
            <div ref={rectRef}></div>
        </div>
    );
};

export default DrawArea;
